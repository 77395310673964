import { useState, useRef, useEffect, useContext } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import classNames from 'classnames';
import { RadioButton } from 'primereact/radiobutton';
import { ColorModeAtom } from '../atoms';
import { useRecoilState } from 'recoil';

export function Settings(props) {
    const [mmurl, setMmurl] = useState(window.localStorage["mmurl"]);
    const toast = useRef(null);
    const [colorMode, setColorMode] = useRecoilState(ColorModeAtom);

    const handleMmurlChange = (e) => setMmurl(e.target.value);
    const handleSave = (e) => {
        window.localStorage["mmurl"] = mmurl;
        toast.current.show({severity: 'success', summary: 'Settings are saved', detail: 'F5 to reload and apply settings'});
    }

    const [scale, setScale] = useState(14);
    const scales = [12, 13, 14, 15, 16];

    const themes = [
        { name: 'indigo', color: '#2f8ee5' },
        { name: 'pink', color: '#E91E63' },
        { name: 'purple', color: '#9C27B0' },
        { name: 'deeppurple', color: '#673AB7' },
        { name: 'blue', color: '#2196F3' },
        { name: 'lightblue', color: '#03A9F4' },
        { name: 'cyan', color: '#00BCD4' },
        { name: 'teal', color: '#009688' },
        { name: 'green', color: '#4CAF50' },
        { name: 'lightgreen', color: '#8BC34A' },
        { name: 'lime', color: '#CDDC39' },
        { name: 'yellow', color: '#FFEB3B' },
        { name: 'amber', color: '#FFC107' },
        { name: 'orange', color: '#FF9800' },
        { name: 'deeporange', color: '#FF5722' },
        { name: 'brown', color: '#795548' },
        { name: 'bluegrey', color: '#607D8B' }
    ];

    const menuThemes = [
        { name: 'light', color: '#FDFEFF' },
        { name: 'dark', color: '#434B54' },
        { name: 'indigo', color: '#1A237E' },
        { name: 'bluegrey', color: '#37474F' },
        { name: 'brown', color: '#4E342E' },
        { name: 'cyan', color: '#006064' },
        { name: 'green', color: '#2E7D32' },
        { name: 'deeppurple', color: '#4527A0' },
        { name: 'deeporange', color: '#BF360C' },
        { name: 'pink', color: '#880E4F' },
        { name: 'purple', color: '#6A1B9A' },
        { name: 'teal', color: '#00695C' }
    ];

    const topbarThemes = [
        { name: 'lightblue', color: '#2E88FF' },
        { name: 'dark', color: '#363636' },
        { name: 'white', color: '#FDFEFF' },
        { name: 'blue', color: '#1565C0' },
        { name: 'deeppurple', color: '#4527A0' },
        { name: 'purple', color: '#6A1B9A' },
        { name: 'pink', color: '#AD1457' },
        { name: 'cyan', color: '#0097A7' },
        { name: 'teal', color: '#00796B' },
        { name: 'green', color: '#43A047' },
        { name: 'lightgreen', color: '#689F38' },
        { name: 'lime', color: '#AFB42B' },
        { name: 'yellow', color: '#FBC02D' },
        { name: 'amber', color: '#FFA000' },
        { name: 'orange', color: '#FB8C00' },
        { name: 'deeporange', color: '#D84315' },
        { name: 'brown', color: '#5D4037' },
        { name: 'grey', color: '#616161' },
        { name: 'bluegrey', color: '#546E7A' },
        { name: 'indigo', color: '#3F51B5' }
    ];

    const decrementScale = () => {
        setScale((prevState) => prevState - 1);
    }

    const incrementScale = () => {
        setScale((prevState) => prevState + 1);
    }

    const applyScale = () => {
        document.documentElement.style.fontSize = scale + 'px';
    };

    useEffect(() => {
        applyScale();
    }, [scale]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card title="Settings" className="shadow-4">
            <Toast ref={toast}></Toast>
            <div className="layout-config-panel flex flex-column">
                {/*
                <div className="p-px-3 p-pt-3">
                    <h5>Theme Customization</h5>
                </div>
                */}
                <hr className="mb-0" />

                <div className="layout-config-options p-3">

                    <h6>Layout/Theme Scale</h6>
                    <div className="flex align-content-start">
                        <Button type="button" icon="pi pi-minus" onClick={() => decrementScale()} className="p-button-rounded p-button-text flex align-items-center" disabled={scale === scales[0]}></Button>
                        {
                            scales.map((s, i) => {
                                return <i key={i} className={classNames('pi m-1 scale-icon flex align-items-center', { 'pi-circle-fill': s === scale }, { 'pi-circle': s !== scale})}></i>
                            })
                        }
                        <Button type="button" icon="pi pi-plus" onClick={() => incrementScale()} className="p-button-rounded p-button-text flex align-items-center" disabled={scale === scales[scales.length - 1]}></Button>
                    </div>

                    <h6>Layout Mode</h6>
                    <div className="flex">
                        <div className="flex align-content-center">
                            <RadioButton id="light" name="darkMenu" value="light" checked={colorMode === 'light'} onChange={(e) => setColorMode(e.value)} />
                            <label htmlFor="light" className="ml-2">Light</label>
                        </div>
                        <div className={classNames('flex align-content-center', 'ml-4')}>
                            <RadioButton id="dark" name="darkMenu" value="dark" checked={colorMode === 'dark'} onChange={(e) => setColorMode(e.value)}  />
                            <label htmlFor="dark" className="ml-2">Dark</label>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div>                
                <div className="p-field grid">
                    <label htmlFor="mmurlInput" className="col-2 ml-3 flex align-items-center" style={{width:'100px'}}>API URL</label>
                    <div className="col-10 flex align-items-center">
                        <InputText id="mmurlInput" value={mmurl} style={{width:'50%'}} type="text" onChange={handleMmurlChange}/>
                        <small id="mmurl-help" className="block mx-2">ex) http://localhost:8383</small>
                    </div>
                </div>
                <Button label="Save" className="mt-4" onClick={handleSave}/>
            </div>
        </Card>
    )
}