import { useState, useRef, useEffect, useContext, useCallback, useMemo } from 'react';
import { Card } from 'primereact/card';
import { ListBox } from 'primereact/listbox';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import ReactECharts from 'echarts-for-react';
//import { ColorModeContext } from '../App';
//import { AppContext } from '../index';
import { AppStateAtom, ColorModeAtom } from '../atoms';
import { useRecoilValue } from 'recoil';
import { ObixUtil } from '../service/ObixUtil';
import { jsonToCsv } from '../service/CsvUtil';
import dateFormat from "dateformat";

export function Statistics(props) {
    //props.site 
    //props.siteInfo
    //const appContext = useContext(AppContext);
    const [ selected, setSelected ] = useState("");

    const setUrl = useCallback((url) => {
        setSelected(url);
    }, []);

    const charts = useMemo(() => {
        return <Charts url={selected} siteInfo={props.siteInfo}/>;
    }, [selected]); 

    const deviceNodes = useMemo(() => {
        return <DeviceNodes onSelected={setUrl} siteInfo={props.siteInfo}/>;
    }, [selected, props.siteInfo]); 

    return (
        <div className="grid">
            <div className="col-12 md:col-9">
                {charts}
            </div>
            <div className="col-12 md:col-3">
                {deviceNodes}
            </div>
        </div>
    );
}

function DeviceNodes(props) {
    // props.siteInfo: siteInfo 
    // props.onSelected;
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    //const [urls, setUrls] = useState([]);
    const [urlModel, setUrlModel] = useState([]);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        console.dir(props.siteInfo);
        if (!props.siteInfo.edges) return;
        const edges = props.siteInfo.edges.join(',');
        let body = {
            o: 'obix',
            c: [
                { o: 'str', name: 'edge', val: edges }
            ]
        }
        ObixUtil.invokeObix(appState.mmurl + '/obix/histories/getAccumulatedUrls', body, 
            (resp) => {
                const a = [];
                for (const c of resp.data.c) {
                    a.push({
                        label: c.val,  
                    });
                }
                setUrlModel(a);
                if (a.length > 0) {
                    setUrl(a[0]);
                    props.onSelected(a[0].label);
                }
            }, 
            (err) => {
                console.error(err);
            }
        );
    }, [appState.mmurl, props.siteInfo]);

    function onListSelect(e) {
        setUrl(e.value);
        //console.dir(e);
        props.onSelected(e.value?.label);
    }

    function itemTemplate(option) {
        //console.dir(option);
        return (
            <div>
                <i className="pi pi-caret-right"/> 
                <span>{option.label}</span>
            </div>
        );
    }

    const title = <span>
        <h5 className="p-m-0">
            <i className="pi pi-eye"></i> 
            <span> Choose Device </span>
            {/*<ToggleButton checked={pinned} onChange={onChangePin} onLabel="" offLabel="" onIcon="pi pi-paperclip" offIcon="pi pi-paperclip" className="narrow-toggle-button" />*/}
        </h5>
    </span>;

    return (
        <Card title={title}>
            {/*<Menu model={urlModel} className="width-100"/>*/}
            <ListBox optionLabel="label" options={urlModel} value={url} onChange={onListSelect} itemTemplate={itemTemplate} className="narrow"/>
        </Card>
    );
}

function Charts(props) {
    // props.url = 선택된 노드의 URL = port/addr/obj/url@obd  
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const [edge, setEdge] = useState("");
    const [url, setUrl] = useState("");
    const [deviceName, setDeviceName] = useState("");
    const [date, setDate] = useState(new Date());  
    //const [chartMeta, setChartMeta] = useState({});  // device의 charts 정보를 담음 
    const [chartData, setChartData] = useState([]);  // chart data 
    const [devType, setDevType] = useState("Unknown");  // device의 type 
    const [chartWidgets, setChartWidgets] = useState([]);  // chart widgets
    const [columns, setColumns] = useState([]);  // DataTable의 column 들 
    const [start, setStart] = useState(null);  // history 요청할 시작 날짜. (XML Date)
    const [end, setEnd] = useState(null);  // history 요청할 시작 날짜. (XML Date)
    const [loading, setLoading] = useState(false);  // loading icon 보여줄건가 
    const [period, setPeriod] = useState('PT15M');
    const toast = useRef(null);
    const dt = useRef(null);
    const cal = useRef(null);  // calendar 

    useEffect(() => {
        if (!props.url) return;
        const words = props.url.split('@');  // props.url 은 port/1/url@edge 형식임 
        if (words.length < 2) return; 

        setEdge(words[1]);
        setUrl(words[0]);    
    }, [props.url]);

    useEffect(() => {
        if (!edge || !url) return;
        // 해당 노드의 accumulation 메타 정보 가져오기 
        let body = {
            o: 'obix',
            c: [
                { o: "str", name: "edge", val: edge }, 
                { o: "str", name: "uri", val: url },
            ]
        }

        ObixUtil.invokeObix(appState.mmurl + '/obix/histories/getDeviceInfo', body, 
            (resp) => {
                const type = ObixUtil.find(resp.data, "devType");
                setDevType(type?.val);
                setDeviceName(ObixUtil.find(resp.data, "deviceName")?.val + "@" + edge);
            }, 
            (err) => {
                console.error(err);
            }
        );
    }, [edge, url, appState.mmurl])

    // date state가 바뀌면 start/end도 바꿈. 
    useEffect(() => {
        if (date === null) return;
        let s = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
        let e = new Date(date.getFullYear(), date.getMonth(), date.getDate()+1, 0, 0, 0, 0);
        if (period === 'P1D') {
            s = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
            e = new Date(date.getFullYear(), date.getMonth()+1, 1, 0, 0, 0, 0);
        } else if (period === 'P1M') {
            s = new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0);
            e = new Date(date.getFullYear()+1, 0, 1, 0, 0, 0, 0);
        } else if (period === 'P1Y') {
            s = new Date(2021, 0, 1, 0, 0, 0, 0);
            e = new Date(date.getFullYear()+1, 0, 1, 0, 0, 0, 0);
        }
        setStart(s);
        setEnd(e);
    }, [date, period]);

    // queryDemandTable 로 데이터 읽어오기 
    useEffect(() => {
        if (!edge || !url) return;
        let invokeUrl = appState.mmurl + '/obix/histories/history/queryDemandTable';
        let body = {
            o: 'obix',
            c: [                
                { o: 'abstime', name: "start", val: ObixUtil.toIsoDate(start) },
                { o: 'abstime', name: "end", val: ObixUtil.toIsoDate(end) },
                { o: 'str', name: 'uri', val: url },
                { o: 'str', name: 'edge', val: edge },
                { o: 'str', name: 'duration', val: period }  //TODO 바꾸기 
            ]
        }
        //console.dir(body);
        setLoading(true);

        ObixUtil.invokeObix(invokeUrl, body, 
            (resp) => {
                for (let i = 0; i < resp.data.length; i++) {
                    for (const prop in resp.data[i]) {
                        if (typeof resp.data[i][prop] === 'number') {
                            resp.data[i][prop] = Math.round(resp.data[i][prop]*1000.0)/1000.0;
                        } else if (prop === "ttime") {
                            // 2023-09-11T00:00:00 에서 T를 없앤다. 
                            resp.data[i][prop] = resp.data[i][prop].replace(/T/g, ' ');
                        }
                    }
                }
                setChartData(resp.data);
                const c = [];
                for (const item of resp.data) {
                    if ("__error__" in item) {
                        continue;
                    } else {
                        for (const field in item) {
                            {/* Camel Case splitting */}
                            if (field === "ttime") {
                                c.push(<Column key={field} field={field} header="Time" style={{ flexGrow: 1, flexBasis: '160px' }}/>);                            
                            } else {
                                c.push(<Column key={field} field={field} header={ObixUtil.humanizeCamelCase(field)} 
                                    style={{ flexGrow: 1, flexBasis: '100px' }}/>);                            
                            }
                        }
                        break;
                    }
                }
                setColumns(c);
                setLoading(false);
            },
            (err) => {
                console.error(err);
                setLoading(false);
            }
        );
        // period 도 아래에 넣었는데, period와 start/end가 따로 따로 업뎃되어서
        // start/end 바뀌기 전에 period가 바뀌어버리면 
        // Days --> hour 로 바뀌면 한달치가 hour 로 나오는 문제가 있음. 그래서 period는 의존성에서 뺌 
    }, [start, end, edge, url, appState.mmurl])

    // Chart 객체를 새로 만든다. 
    useEffect(() => {
        if (devType === "PCS") {
            setChartWidgets(<PcsChartView table={chartData}/>);
        } else if (devType === "PVInverter") {
            setChartWidgets(<PvChartView table={chartData}/>);
        } else if (devType === "DCDC") {
            setChartWidgets(<DcdcChartView table={chartData}/>);
        } else if (devType === "Genset") {
            setChartWidgets(<GensetChartView table={chartData}/>);
        } else if (devType === "ElecMeter") {
            setChartWidgets(<MeterChartView table={chartData}/>);
        }
    }, [chartData, devType]);

    const onPrev = (e) => {        
        // setDate((prev) => new Date(prev.setDate(prev.getDate()-1)))
        setDate(prev => {
            const d = new Date(prev);
            d.setDate(d.getDate()-1);
            return d;
        });
    }

    const onNext = (e) => {
        setDate(prev => {
            const d = new Date(prev);
            d.setDate(d.getDate()+1);
            return d;
        });
    }

    const exportCsv = () => {
        //dt.current.exportCSV();
        const d = dateFormat(start, "yyyy-mm-dd");
        jsonToCsv(chartData, `${edge}-${url}-${d}-stat`, true);
    };

    const onPeriodChanged = (p) => {
        setPeriod(p.value);
    }

    const periodSelectItems = [
        {label: '15min', value: 'PT15M'},
        {label: 'Hour', value: 'PT1H'},
        {label: 'Day', value: 'P1D'},
        {label: 'Month', value: 'P1M'},
        /*{label: '년', value: 'year'}*/
    ];

    const dtHeader = <div style={{textAlign:'right'}}>
        <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={exportCsv}></Button>
    </div>;

    const title = <span>
        <h5 className="p-m-0"><i className="pi pi-chart-bar"></i> Statistics for "{url}" ({deviceName})</h5>
    </span>;

    return (
        <Card title={title}>
            <div className="flex justify-content-between">
                <div className="flex align-items-center">
                    <label htmlFor="date" className="mx-1">Choose Date: </label>
                    <Calendar id="date" ref={cal} selectionMode="single" value={date} onChange={(e) => setDate(e.value)} dateFormat="yy.m.d" className="mx-1"/>
                    <Button icon="pi pi-angle-left" className="mx-1" onClick={onPrev}/>
                    <Button icon="pi pi-angle-right" className="mx-1" onClick={onNext}/>
                    <Button label="Today" className="mx-1" onClick={(e) => setDate(new Date())}/>
                    { loading ? <i className="pi pi-spin pi-spinner ml-2 font-bold" style={{'fontSize': '1.5em', 'color': 'gray'}}/> : null }
                </div>
                <div>
                    <SelectButton value={period} options={periodSelectItems} onChange={onPeriodChanged} unselectable={false}/>
                </div>
            </div>
            <hr/>
            <TabView>
                <TabPanel header="Charts">
                    <div>
                        {chartWidgets}
                    </div>
                </TabPanel>
                <TabPanel header="Table">
                    <DataTable value={chartData} header={dtHeader} ref={dt} size="normal" showGridlines={false} stripedRows={false} 
                        scrollable scrollDirection="both" scrollHeight="600px" virtualScrollerOptions={{ itemSize: 46 }}>
                        {columns}
                    </DataTable>
                </TabPanel>
            </TabView>  
            <Toast ref={toast}></Toast>   
        </Card>
    )
}

function PcsChartView(props) {
    // props.table
    return (
        <div>
            <EnergyMetrics table={props.table} input="charged" output="discharged" unit="kWh"/>
            <GenericBarChart title={"Charged/Discharged Energy (AC)"} fields={["discharged", "charged"]} table={props.table} unit="kWh"/>
            <GenericBarChart title={"Charged/Discharged Energy (DC)"} fields={["dischargedDc", "chargedDc"]} table={props.table} unit="kWh"/>
        </div>
    );
}

function PvChartView(props) {
    // props.table
    return (
        <div>
            <EnergyMetrics table={props.table} input="generated" unit="kWh"/>
            <GenericBarChart title={"Generated Energy (AC)"} fields={["generated"]} table={props.table} unit="kWh"/>
            <GenericBarChart title={"Generated Energy (DC)"} fields={["generatedDc"]} table={props.table} unit="kWh"/>
        </div>
    );
}

function DcdcChartView(props) {
    // props.table
    return (
        <div>
            <EnergyMetrics table={props.table} input="generated" unit="kWh"/>
            <GenericBarChart title={"Generated Energy"} fields={["generated"]} table={props.table} unit="kWh"/>
            <GenericBarChart title={"Charged/Discharged Energy"} fields={["discharged", "charged"]} table={props.table} unit="kWh"/>
        </div>
    );
}

function GensetChartView(props) {
    // props.table
    return (
        <div>
            <EnergyMetrics table={props.table} input="generated" unit="kWh"/>
            <GenericBarChart title={"Generated Energy"} fields={["generated"]} table={props.table} unit="kWh"/>
            <GenericBarChart title={"Running Hours"} fields={["runningHours", "runningHoursAcc"]} table={props.table} unit="Hour"/>
        </div>
    );
}

function MeterChartView(props) {
    // props.table
    return (
        <div>
            <EnergyMetrics table={props.table} input="energy" output="energyExport" unit="kWh"/>
            <GenericBarChart title={"Active Energy"} fields={["energy", "energyExport"]} table={props.table} unit="kWh"/>
            <GenericBarChart title={"Reactive Energy"} fields={["reactiveEnergy", "reactiveEnergyExport"]} table={props.table} unit="kVARh"/>
            <GenericBarChart title={"Apparent Energy"} fields={["apparentEnergy", "apparentEnergyExport"]} table={props.table} unit="kVAh"/>
        </div>
    );
}

function EnergyMetrics(props) {
    // props.table 
    // props.unit : kWh or Ah
    // props.input : input name (charged or energy)
    // props.output : output name (discharged or energyExport)
    const [ input, setInput ] = useState(0);
    const [ output, setOutput ] = useState(0);
    const [ ratio, setRatio ] = useState(0);
    useEffect(() => {
        let c = 0.0;
        let d = 0.0;
        for (const item of props.table) {
            if (props.input in item) {
                c += item[props.input];
            }
            if (props.output && props.output in item) {
                d += item[props.output];
            }
        }
        setInput(Math.round(c*10.0)/10.0);
        setOutput(Math.round(d*10.0)/10.0);
        let e = 0;
        if (c && d && c !== 0.0) {
            e = d / c;
        } 
        setRatio(Math.round(e*1000)/10.0);
    }, [props.table, props.unit, props.input, props.output]);

    if (props.output) {
        return (
            <div className="mt-2 text-center" >            
                <Message severity="success" text={`${ObixUtil.humanizeCamelCase(props.input)}: ${input} ${props.unit} / ${ObixUtil.humanizeCamelCase(props.output)}: ${output} ${props.unit}`} className="ml-5 mr-3 mb-2 font-bold"/>
                <Message severity="info" text={`Out/In Ratio: ${ratio} %`} className="font-bold"/>
            </div>
        );
    } else {
        return (
            <div className="mt-2 text-center">            
                <Message severity="success" text={`${ObixUtil.humanizeCamelCase(props.input)}: ${input} ${props.unit}`} className="ml-5 mr-3 mb-2 font-bold"/>
            </div>
        );
    }
}

export function GenericBarChart(props) {
    // props.title:  Title@unit 형태. 예를 들어 Voltage@kW 
    // props.fields:  fields 배열 
    // props.table:  table 형태 
    // props.unit: yAxis label
    // props.title: 제목 
    //const colorModeContext = useContext(ColorModeContext);
    const colorMode = useRecoilValue(ColorModeAtom);
    const [title, setTitle] = useState("???");
    const chartMemo = useMemo(() => {
        // props.fields 가 object로 날아옴. 
        if (!props.fields || !props.table) {
            console.warn("props.table is empty?");
            return null;
        }
        const series = [];
        const legends = [];
        for (const field of props.fields) {
            const data = [];
            for (const item of props.table) {
                if (field in item)
                    data.push([item.ttime, item[field]]);
            }
            if (data.length > 0) {
                const humanizedField = ObixUtil.humanizeCamelCase(field);
                series.push({
                    name: humanizedField,
                    type: 'bar', 
                    showSymbol: false,
                    data: data
                });
                legends.push(humanizedField);
            }
        }

        const options = {
            xAxis: {
                type: 'time',
                name: 'Time',
                splitLine: {
                    show: false
                }, 
            },
            yAxis: {
                type: 'value',
                name: props.unit,
                splitLine: {
                    show: false
                },
            },
            legend: {
                data: legends
            },
            tooltip: {
                trigger: 'axis',
            },    
            toolbox: {
                feature: {
                    /*
                    saveAsImage: {},
                    dataView: {
                        optionToContent: exportCsv
                    },
                    */
                    dataZoom: {
                        show: true
                    }
                },
                top: 20
            },    
            series: series
        };

        if (series.length > 0) {
            return <ReactECharts option={options} notMerge={true} style={props.cstyle} opts={{ renderer: 'canvas' }} theme={colorMode}/>;
        } else {
            return null;
        }
    }, [props.cstyle, props.fields, props.table, props.title, props.unit, colorMode]); 

    return (
        <div>
            {chartMemo ? <h5 className="mt-2"><i className="pi pi-caret-right"/>{props.title}</h5>: null}
            {chartMemo}
            <br/>
        </div>
    );
}