import { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { ReportObd } from '../sites/ReportObd';

export function Report(props) {
    // props.site
    const [report, setReport] = useState(null);
    useEffect(() => {
        if (props?.site === "oebyungdo") {
            setReport(<ReportObd site={props.site} siteInfo={props.siteInfo} />);
        } else {
            setReport(<DefaultReport />);
        }
    }, [props.site])
    return (
        <div>{report}</div>
    );
}

export function DefaultReport(props) {
    return (
        <div>Report</div>
    );
}