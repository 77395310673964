import { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { BatteryHorizIcon, GensetIcon, HvacIcon, ImdIcon, MeterIcon, PcsIcon, PvIcon, IconLabel, HorizLine, Horiz90Line, ElecLine } from '../service/SvgIcons';
import { ObixUtil } from '../service/ObixUtil';

export function SingleLineSvgObd(props) {
    // props.objmap
    // props.site
    // props.siteInfo
    const ix0 = 125;
    const ix1 = 630;
    const ix2 = 820;
    const iy0 = 50;
    const iy1 = 220;
    const iy2 = 385;
    const iw = 75;  // icon width
    const ih = 75;  // icon height
    const jx = 430;  // joint x
    const fs = 24;  // font size

    const [gensetObj, setGensetObj] = useState(null);
    const [pcsObj, setPcsObj] = useState(null);
    const [pvObj, setPvObj] = useState(null);
    const [batObj, setBatObj] = useState(null);
    const [meterObj, setMeterObj] = useState(null);

    useEffect(() => {
        //console.dir(props.objmap);
        if (!props?.objmap) return;
        setPcsObj(props.objmap.get('pcs/11/pcs'));
        setGensetObj(props.objmap.get('genset/1/genset'));
        setPvObj(props.objmap.get('pv/11/inverter'));
        setBatObj(props.objmap.get('bat/1/bms'));
        setMeterObj(props.objmap.get('meter/1/meter/ch1'));
    }, [props.objmap]);

    return (
        <svg width="100%" height="100%" viewBox="0 0 1024 526">
            <g>
                <ElecLine path={[ix1, iy0+ih/2, jx, iy0+ih/2, jx, iy1+ih/2]} obj={gensetObj} power="+power"/>              
                <GensetIcon x={ix1} y={iy0} w={iw} h={ih}/>
                <IconLabel x={ix1} y={iy0+ih+fs} h={fs} type="ac" obj={gensetObj}/>

                <ElecLine path={[ix1, iy1+ih/2, jx, iy1+ih/2]} obj={pcsObj} power="+power"/>              
                <PcsIcon x={ix1} y={iy1} w={iw} h={ih}/>
                <IconLabel x={ix1} y={iy1+ih+fs} h={fs} type="ac" obj={pcsObj}/>
                <ElecLine path={[ix2, iy1+ih/2, ix1+iw, iy1+ih/2]} obj={batObj}/>              
                <BatteryHorizIcon x={ix2} y={iy1} w={iw} h={ih}/>
                <IconLabel x={ix2} y={iy1+ih+fs} h={fs} type="bat" obj={batObj}/>

                <ElecLine path={[ix1, iy2+ih/2, jx, iy2+ih/2, jx, iy1+ih/2]} obj={pvObj} />              
                <PcsIcon x={ix1} y={iy2} w={iw} h={ih}/>
                <IconLabel x={ix1} y={iy2+ih+fs} h={fs} type="ac" obj={pvObj}/>
                <ElecLine path={[ix2, iy2+ih/2, ix1+iw, iy2+ih/2]} obj={pvObj} cb="dcMcClosed"/>              
                <PvIcon x={ix2} y={iy2} w={iw} h={ih}/>
                <IconLabel x={ix2} y={iy2+ih+fs} h={fs} type="pv" obj={pvObj}/>

                <MeterIcon x={ix0} y={iy1} w={iw} h={ih}/>
                <ElecLine path={[ix0+iw, iy1+ih/2, jx, iy1+ih/2]} obj={meterObj} power="-power"/>              
                <IconLabel x={ix0} y={iy1+ih+fs} h={fs} type="ac" obj={meterObj}/>          
            </g>
        </svg>
    );
}
