import { useState, useEffect, useContext, useRef, forwardRef } from 'react';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import ReactToPrint from 'react-to-print';

//import { AppContext } from '../index';
import { AppStateAtom } from '../atoms';
import { useRecoilValue } from 'recoil';
//import { AuthContext } from '../index';
import { ObixUtil } from '../service/ObixUtil';

import { printf } from "fast-printf";

/**
 * 외병도를 위한 리포트 기능 
 * @param {*} props 
 * @returns 
 */
export function ReportObd(props) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const onChangeDate = (d) => {
        setSelectedDate(d);
    }

    return (
        <div>  
            <SelectDate date={selectedDate} onChangeDate={onChangeDate}/>
            <br/>
            <ReportTabs date={selectedDate}/>
        </div>
    );
}

function SelectDate(props) {
    // props.date: 선택된 날짜 
    // props.onChangeDate: 날짜가 변경되었을때 콜백 
    const [date, setDate] = useState(props.date);
    const cal = useRef(null);  // calendar 
    const [loading, setLoading] = useState(false);  // loading icon 보여줄건가 

    useEffect(() => {
        props.onChangeDate(date);
    }, [date]);

    const onPrev = (e) => {        
        // setDate((prev) => new Date(prev.setDate(prev.getDate()-1)))
        setDate(prev => {
            //console.log(prev);
            return new Date(prev.setDate(prev.getDate()-1));
        });
    }

    const onNext = (e) => {
        setDate(prev => {
            return new Date(prev.setDate(prev.getDate()+1));
        });
    }

    const onHideCalendar = () => {
        //console.log(date);
    }

    const title = <span>
        <h5 className="p-m-0"><i className="pi pi-calendar"></i> 보고서 날짜 선택</h5>
    </span>;

    return (
        <Card title={title}>
            <div className="flex justify-content-between">
                <div className="flex align-items-center">
                    <label htmlFor="date">Choose Date: </label>
                    <Calendar id="date" ref={cal} selectionMode="single" value={date} onChange={(e) => setDate(e.value)} onHide={onHideCalendar} dateFormat="yy.m.d" className="mx-1"/>
                    <Button icon="pi pi-angle-left" className="mx-1" onClick={onPrev}/>
                    <Button icon="pi pi-angle-right" className="mx-1" onClick={onNext}/>
                    <Button label="Today" className="mx-1" onClick={(e) => setDate(new Date())}/>
                    { loading ? <i className="pi pi-spin pi-spinner ml-2 font-bold" style={{'fontSize': '1.5em', 'color': 'gray'}}/> : null }
                </div>
            </div>
        </Card>
    );
}

function ReportTabs(props) {
    // props.date: 선택된 날짜 
    const gensetLogRef = useRef(null);
    const pvLogRef = useRef(null);

    const title = <span>
        <h5 className="p-m-0"><i className="pi pi-file"></i> 시스템 운영 보고서</h5>
    </span>;

    const pageStyle = 
        `h3 {
            font-size: 1.5em; 
            text-align: center;
            margin-top: 5em;
        }
        .text-lg {
            font-size: 1em;
        }
        .text-sm {
            font-size: 0.6em;
        }
        .my-2 {
            padding: 2 0 2 0;
        }
        table.report {
            border-collapse: collapse;
            width: 100%;
        }
        table.report th, table.report td {
            border:1px solid #9e9e9e;
            padding: 1px;
            text-align: center;
            font-size: 0.7em;
        }
        `;
    // https://velog.io/@kec0130/react-to-print
    // copyStyles=false 로 하지 않으면, 아예 보이지 않음...     
    return (
        <Card title={title}>
            <TabView>
                <TabPanel header="디젤발전기 운용">
                    <ReactToPrint content={() => gensetLogRef.current} trigger={() => <button>프린트</button>} copyStyles={false}
                        documentTitle="외병도 발전기 운영 리포트" pageStyle={pageStyle}/>
                    <GensetLog date={props.date} ref={gensetLogRef}/>
                </TabPanel>
                <TabPanel header="태양광발전 운용">
                    <ReactToPrint content={() => pvLogRef.current} trigger={() => <button>프린트</button>} copyStyles={false}
                        documentTitle="외병도 발전기 운영 리포트" pageStyle={pageStyle}/>
                    <PvLog date={props.date} ref={pvLogRef}/>
                </TabPanel>
            </TabView>
        </Card>
    );
}

const calcMinMaxAvg = (resp, name, se, ee) => {
    // resp: response data 
    // name: name of ...
    // se, ee: events 
    // 
    console.log(`se=${JSON.stringify(se)}, ee=${JSON.stringify(ee)}`);
    const s = new Date(se.when);
    const e = new Date(ee.when);
    const minutes = (e.getTime() - s.getTime())/60.0/1000.0;
    const data = {};
    data.minutes = minutes;
    data.min = resp.data[se.index][name];
    data.max = resp.data[se.index][name];
    data.avg = 0;
    data.start = resp.data[se.index][name];    
    data.mid = resp.data[parseInt((se.index + ee.index)/2)][name];
    data.end = resp.data[ee.index][name];
    data.today = 0;
    for (let k = se.index; k < ee.index; k++) {
        const v = resp.data[k][name];
        if (v > data.max) {
            data.max = v;
            data.maxTime =resp.data[k]['ttime'];
        }
        if (v < data.min) {
            data.min = v;
            data.minTime =resp.data[k]['ttime'];
        }
        data.avg += v;
    }
    if (ee.index <= se.index) {
        data.avg = resp.data[se.index][name];
    } else {
        const avg = data.avg / (ee.index - se.index);
        //console.log(`avg=${avg}, avg*10=${avg*10}, round=${Math.round(avg*10)}, round10=${Math.round(avg*10)/10.0}`);
        data.avg = avg;
        //data.avg = Math.round(avg*10)/10.0;
        //data.avg = Math.round(avg);
    }

    data.today = data.end - data.start;

    return data;
}

/**
 * 발전기 기동 리포트 
 * function component는 ref를 달 수 없으므로, forwardRef를 사용해서 안으로 전달해주어야 함. 
 * @param {*} props 
 * @returns 
 */
const GensetLog = forwardRef((props, ref) => {
    // props.date: 선택된 날짜 
    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [table, setTable] = useState([]);
    const [pcsTable, setPcsTable] = useState([]);
    const [gensetEvents, setGensetEvents] = useState([]);
    const [widgets, setWidgets] = useState([]);
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const toast = useRef(null);

    /// 
    useEffect(() => {
        const s = new Date(props.date.getFullYear(), props.date.getMonth(), props.date.getDate(), 0, 0, 0, 0);
        setStart(s);
        const e = new Date(s.getFullYear(), s.getMonth(), s.getDate()+1, 0, 0, 0, 0);
        setEnd(e);
    }, [props.date]);

    /// 발전기 데이터 읽어서 분석 
    useEffect(() => {
        if (!appState.mmurl) return;
        if (!start || !end) return;
        setLoading(true);
        let body = {
            o: 'obix',
            c: [
                { o: 'str', name: 'edge', val: 'obd' },
                { o: 'str', name: 'uri', val: 'genset/1/genset' },
                { o: 'abstime', name: 'start', val: ObixUtil.toIsoDate(start) },
                { o: 'abstime', name: 'end', val: ObixUtil.toIsoDate(end) },
            ]
        };
        console.log(`start=${start}, end=${end}`);
        ObixUtil.invokeObix(appState.mmurl + "/obix/histories/history/queryTable", body, 
            (resp) => {
                //setGensetData(resp.data);
                // 발전기 기동이 언제 시작하고 언제 끝나는지 start/stop pair로 배열에 저장 
                const events = [];
                let state = "stopped";
                for (let i = 0; i < resp.data.length; i++) {
                    const item = resp.data[i];
                    if (item.opMode === "islanding" && state === "stopped") {  // 발전기 기동 시작 
                        events.push({
                            event: "start",
                            when: item.ttime,
                            index: i
                        });
                        state = "islanding";                        
                    } else if (["stopped", "standby~~"].includes(item.opMode) && state === "islanding") {  // 발전기 기동 멈춤, cooldown 포함
                        events.push({
                            event: "end",
                            when: item.ttime,
                            index: i-5  // stop 이전 5분 데이터 
                        });
                        state = "stopped";  
                    }
                }                
                // 만일 마지막 state 가 islanding이면 그걸 마감으로 넣는다. 
                // 하루 끝에 standby 인경우도 있어서... 
                if (["islanding", "standby"].includes(resp.data[resp.data.length-1].opMode)) {
                    events.push({
                        event: "end",
                        when: resp.data[resp.data.length-1].ttime,
                        index: resp.data.length-1
                    });
                }
                setGensetEvents(events);
                /*
    {
        "event": "start", "when": "2022-12-24T00:00:00", "index": 0
    },
    {
        "event": "end", "when": "2022-12-24T00:46:00", "index": 46 
    }
]*/
                console.dir(events);
                let minutes = 0;
                const dataArray = [];
                for (let i = 0; i < events.length/2; i++) {
                    const se = events[i*2];
                    const ee = events[i*2+1];
                    const dataMap = {};
                    dataMap["acVoltageAB"] = calcMinMaxAvg(resp, "acVoltageAB", se, ee);
                    dataMap["acVoltageBC"] = calcMinMaxAvg(resp, "acVoltageBC", se, ee);
                    dataMap["acVoltageCA"] = calcMinMaxAvg(resp, "acVoltageCA", se, ee);
                    dataMap["acCurrentA"] = calcMinMaxAvg(resp, "acCurrentA", se, ee);
                    dataMap["acCurrentB"] = calcMinMaxAvg(resp, "acCurrentB", se, ee);
                    dataMap["acCurrentC"] = calcMinMaxAvg(resp, "acCurrentC", se, ee);
                    dataMap["powerFactor"] = calcMinMaxAvg(resp, "powerFactor", se, ee);
                    dataMap["frequency"] = calcMinMaxAvg(resp, "frequencyA", se, ee);
                    dataMap["power"] = calcMinMaxAvg(resp, "power", se, ee);
                    dataMap["energy"] = calcMinMaxAvg(resp, "generated", se, ee);
                    dataArray.push(dataMap);
                }
                console.dir(dataArray);
                setTable(dataArray);
                setLoading(false);
            },
            (err) => {
                console.error(err);
                toast.current?.show({ severity: 'error', summary: 'Failed to load from mastermind', detail: err });
                setLoading(false);
            }
        );
    }, [appState.mmurl, start, end]);

    useEffect(() => {
        const w = [];
        for (let i = 0; i < table.length; i++) {        
            w.push(<GensetTable key={i} data={table[i]} pcs={pcsTable[i]} se={gensetEvents[i*2]} ee={gensetEvents[i*2+1]}/>);
        }
        setWidgets(w);
    }, [table, pcsTable]);

    // PCS 정보 읽기 
    useEffect(() => {
        if (!gensetEvents) return;
        if (!appState.mmurl) return;
        if (!start || !end) return;
        setLoading(true);
        let body = {
            o: 'obix',
            c: [
                { o: 'str', name: 'edge', val: 'obd' },
                { o: 'str', name: 'uri', val: 'pcs/11/pcs' },
                { o: 'abstime', name: 'start', val: ObixUtil.toIsoDate(start) },
                { o: 'abstime', name: 'end', val: ObixUtil.toIsoDate(end) },
            ]
        };
        console.log(`start=${start}, end=${end}`);
        ObixUtil.invokeObix(appState.mmurl + "/obix/histories/history/queryTable", body, 
            (resp) => {
                console.log(`Read PCSPCS...${gensetEvents}`);
                console.dir(resp.data);
                const dataArray = [];
                for (let i = 0; i < gensetEvents.length/2; i++) {
                    const se = gensetEvents[i*2];
                    const ee = gensetEvents[i*2+1];
                    const dataMap = {};
                    dataMap["acVoltage"] = calcMinMaxAvg(resp, "acVoltageA", se, ee);
                    dataMap["acCurrent"] = calcMinMaxAvg(resp, "acCurrentA", se, ee);
                    dataMap["dcVoltage"] = calcMinMaxAvg(resp, "dcVoltage", se, ee);
                    dataMap["dcCurrent"] = calcMinMaxAvg(resp, "dcCurrent", se, ee);
                    dataArray.push(dataMap);
                }
                console.dir(dataArray);
                setPcsTable(dataArray);
                setLoading(false);
            },
            (err) => {
                console.error(err);
                toast.current?.show({ severity: 'error', summary: 'Failed to load from mastermind', detail: err });
                setLoading(false);
            }
        );
    }, [gensetEvents, appState.mmurl, start, end]);
    return (
        <div ref={ref}>
            <div className="flex align-items-center justify-content-center my-2">
                <h3>발전기 사용일지</h3>
            </div>
            <div className="text-lg my-2">
                {props.date.toLocaleDateString('ko-KR', {weekday: "long", year: "numeric", month: "long", day: "numeric"})}
                { loading ? <i className="pi pi-spin pi-spinner ml-2 font-bold" style={{'fontSize': '1em', 'color': 'gray'}}/> : null }
            </div>
            <br/>
            {widgets}
            <div className="text-lg my-2">
                기타
            </div>
            <small>
                * 고장 및 경보발생 사항 기재
            </small>
            {/*
            <table className="report">
                <tbody>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            */}
            <Toast ref={toast}></Toast>
        </div>
    );
});

function GensetTable(props) {
    // props.data: dataArray의 한줄 
    // props.pcs: pcs data
    // props.se: start event
    // props.ee: end event 
    //console.dir(props.data);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [runMinutes, setRunMinustes] = useState(0);
    useEffect(() => {
        console.dir(props);
        if (props.se && props.ee) {
            const sd = new Date(props.se?.when);
            setStart(`${printf('%02d', sd.getHours())}:${printf('%02d', sd.getMinutes())}`);
            const ed = new Date(props.ee?.when);
            setEnd(`${printf('%02d', ed.getHours())}:${printf('%02d', ed.getMinutes())}`);
            setRunMinustes(Math.floor((ed.getTime() - sd.getTime())/60/1000));
        }
    }, [props.se, props.ee]);
    return (
        <div>
            <div className="text-lg my-2">
                발전기 가동 시간: {start} ~ {end} &nbsp;&nbsp;({Math.floor(runMinutes/60)} 시간 {runMinutes%60} 분)
            </div>
            <div className="text-lg my-2">
                연료 소모량: 
            </div>
            <table className="report">
                <thead>
                    <tr>
                        <th colSpan="3">기계장치</th>
                        <th>단위</th>
                        <th>시작</th>
                        <th>중간</th>
                        <th>종료</th>
                        <th>최소</th>
                        <th>최대</th>
                        <th>평균</th>
                        <th>전력량</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowSpan={10}>발전기</td>
                        <td>발전전력량</td>
                        <td>WH2</td>
                        <td>kWh</td>
                        <td>{props.data['energy'].start}</td>
                        <td>{props.data['energy'].mid}</td>
                        <td>{props.data['energy'].end}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{props.data['energy'].today}</td>
                    </tr>
                    <tr>
                        <td rowSpan={3}>발전전압</td>
                        <td>RS</td>
                        <td>V</td>
                        <td>{props.data['acVoltageAB'].start}</td>
                        <td>{props.data['acVoltageAB'].mid}</td>
                        <td>{props.data['acVoltageAB'].end}</td>
                        <td>{props.data['acVoltageAB'].min}</td>
                        <td>{props.data['acVoltageAB'].max}</td>
                        <td>{Math.round(props.data['acVoltageAB'].avg*10)/10}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>ST</td>
                        <td>V</td>
                        <td>{props.data['acVoltageBC'].start}</td>
                        <td>{props.data['acVoltageBC'].mid}</td>
                        <td>{props.data['acVoltageBC'].end}</td>
                        <td>{props.data['acVoltageBC'].min}</td>
                        <td>{props.data['acVoltageBC'].max}</td>
                        <td>{Math.round(props.data['acVoltageBC'].avg*10)/10}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>TR</td>
                        <td>V</td>
                        <td>{props.data['acVoltageCA'].start}</td>
                        <td>{props.data['acVoltageCA'].mid}</td>
                        <td>{props.data['acVoltageCA'].end}</td>
                        <td>{props.data['acVoltageCA'].min}</td>
                        <td>{props.data['acVoltageCA'].max}</td>
                        <td>{Math.round(props.data['acVoltageCA'].avg*10)/10}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td rowSpan={3}>발전전류</td>
                        <td>R상</td>
                        <td>A</td>
                        <td>{props.data['acCurrentA'].start}</td>
                        <td>{props.data['acCurrentA'].mid}</td>
                        <td>{props.data['acCurrentA'].end}</td>
                        <td>{props.data['acCurrentA'].min}</td>
                        <td>{props.data['acCurrentA'].max}</td>
                        <td>{Math.round(props.data['acCurrentA'].avg*10)/10}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>S상</td>
                        <td>A</td>
                        <td>{props.data['acCurrentB'].start}</td>
                        <td>{props.data['acCurrentB'].mid}</td>
                        <td>{props.data['acCurrentB'].end}</td>
                        <td>{props.data['acCurrentB'].min}</td>
                        <td>{props.data['acCurrentB'].max}</td>
                        <td>{Math.round(props.data['acCurrentB'].avg*10)/10}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>T상</td>
                        <td>A</td>
                        <td>{props.data['acCurrentC'].start}</td>
                        <td>{props.data['acCurrentC'].mid}</td>
                        <td>{props.data['acCurrentC'].end}</td>
                        <td>{props.data['acCurrentC'].min}</td>
                        <td>{props.data['acCurrentC'].max}</td>
                        <td>{Math.round(props.data['acCurrentC'].avg*10)/10}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>역률 PF</td>
                        <td>%</td>
                        <td>{props.data['powerFactor'].start*100}</td>
                        <td>{props.data['powerFactor'].mid*100}</td>
                        <td>{props.data['powerFactor'].end*100}</td>
                        <td>{props.data['powerFactor'].min*100}</td>
                        <td>{props.data['powerFactor'].max*100}</td>
                        <td>{Math.round(props.data['powerFactor'].avg*1000)/10.0}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>주파수 Hz</td>
                        <td>Hz</td>
                        <td>{props.data['frequency'].start}</td>
                        <td>{props.data['frequency'].mid}</td>
                        <td>{props.data['frequency'].end}</td>
                        <td>{props.data['frequency'].min}</td>
                        <td>{props.data['frequency'].max}</td>
                        <td>{Math.round(props.data['frequency'].avg*10)/10}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>출력전력</td>
                        <td>kW</td>
                        <td>{props.data['power'].start}</td>
                        <td>{props.data['power'].mid}</td>
                        <td>{props.data['power'].end}</td>
                        <td>{props.data['power'].min}<br/>({props.data['power'] ? printf('%02d:%02d', new Date(props.data['power'].minTime).getHours(), new Date(props.data['power'].minTime).getMinutes()) : ""})</td>
                        <td>{props.data['power'].max}<br/>({props.data['power'] ? printf('%02d:%02d', new Date(props.data['power'].maxTime).getHours(), new Date(props.data['power'].maxTime).getMinutes()) : ""})</td>
                        <td>{printf("%.1f", props.data['power'].avg)}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td rowSpan={4}>축전지<br/>충전기</td>
                        <td colSpan={2}>입력전압</td>
                        <td>V</td>
                        <td>{props.pcs ? props.pcs['acVoltage']?.start : ""}</td>
                        <td>{props.pcs ? props.pcs['acVoltage']?.mid : ""}</td>
                        <td>{props.pcs ? props.pcs['acVoltage']?.end : ""}</td>
                        <td>{props.pcs ? props.pcs['acVoltage']?.min : ""}</td>
                        <td>{props.pcs ? props.pcs['acVoltage']?.max : ""}</td>
                        <td>{props.pcs ? printf("%.1f", props.pcs['acVoltage']?.avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>입력전류</td>
                        <td>A</td>
                        <td>{props.pcs ? props.pcs['acCurrent']?.start : ""}</td>
                        <td>{props.pcs ? props.pcs['acCurrent']?.mid : ""}</td>
                        <td>{props.pcs ? props.pcs['acCurrent']?.end : ""}</td>
                        <td>{props.pcs ? props.pcs['acCurrent']?.min : ""}</td>
                        <td>{props.pcs ? props.pcs['acCurrent']?.max : ""}</td>
                        <td>{props.pcs ? printf("%.1f", props.pcs['acCurrent']?.avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>출력전압</td>
                        <td>V</td>
                        <td>{props.pcs ? props.pcs['dcVoltage']?.start : ""}</td>
                        <td>{props.pcs ? props.pcs['dcVoltage']?.mid : ""}</td>
                        <td>{props.pcs ? props.pcs['dcVoltage']?.end : ""}</td>
                        <td>{props.pcs ? props.pcs['dcVoltage']?.min : ""}</td>
                        <td>{props.pcs ? props.pcs['dcVoltage']?.max : ""}</td>
                        <td>{props.pcs ? printf("%.1f", props.pcs['dcVoltage']?.avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>충전전류</td>
                        <td>A</td>
                        <td>{props.pcs ? props.pcs['dcCurrent']?.start : ""}</td>
                        <td>{props.pcs ? props.pcs['dcCurrent']?.mid : ""}</td>
                        <td>{props.pcs ? props.pcs['dcCurrent']?.end : ""}</td>
                        <td>{props.pcs ? props.pcs['dcCurrent']?.min : ""}</td>
                        <td>{props.pcs ? props.pcs['dcCurrent']?.max : ""}</td>
                        <td>{props.pcs ? printf("%.1f", props.pcs['dcCurrent']?.avg) : ""}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <br/>
        </div>
    );
}

const calcMinMaxAvgPv = (resp, name) => {
    // resp: response data 
    // name: name of ...
    // 

    const data = {};
    data.min = resp.data.length > 0 ? resp.data[0][name] : 0;
    data.max = resp.data.length > 0 ? resp.data[0][name] : 0;
    data.minTime = resp.data.length > 0 ? resp.data[0]['ttime'] : "";
    data.maxTime = resp.data.length > 0 ? resp.data[0]['ttime'] : "";
    data.avg = 0;
    data.today = 0;  // 적산량 하루치 계산 
    //data.start = resp.data[se.index][name];
    //data.mid = resp.data[parseInt((se.index + ee.index)/2)][name];
    //data.end = resp.data[ee.index][name];
    for (let k = 0; k < resp.data.length; k++) {
        let v = 0;
        if (resp.data[k][name])
            v = resp.data[k][name];
        if (v > data.max) {
            data.max = v;
            data.maxTime =resp.data[k]['ttime'];
        }
        if (v < data.min) {
            data.min = v;
            data.minTime =resp.data[k]['ttime'];
        }
        data.avg += v;
        const ttime = resp.data[k]['ttime'];
        if (ttime.includes('T02:00:')) {
            data.h2 = v;
        } else if (ttime.includes('T04:00:')) {
            data.h4 = v;
        } if (ttime.includes('T06:00:')) {
            data.h6 = v;
        } if (ttime.includes('T08:00:')) {
            data.h8 = v;
        } if (ttime.includes('T10:00:')) {
            data.h10 = v;
        } if (ttime.includes('T12:00:')) {
            data.h12 = v;
        } if (ttime.includes('T14:00:')) {
            data.h14 = v;
        } if (ttime.includes('T16:00:')) {
            data.h16 = v;
        } if (ttime.includes('T18:00:')) {
            data.h18 = v;
        } if (ttime.includes('T20:00:')) {
            data.h20 = v;
        } if (ttime.includes('T22:00:')) {
            data.h22 = v;
        } if (ttime.includes('T00:00:')) {
            data.h24 = v;
        } 
    }

    if (resp.data.length > 0) {
        const avg = data.avg / (resp.data.length);
        data.avg = avg;
    } else {
        data.avg = 0;
    }

    data.today = resp.data[resp.data.length-1][name] - resp.data[0][name];
    
    return data;
}

const PvLog = forwardRef((props, ref) => {
    // props.date: 선택된 날짜 
    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [inverterMap, setInverterMap] = useState({});
    const [pcsMap, setPcsMap] = useState({});
    const [meterMap, setMeterMap] = useState({});
    const [batMap, setBatMap] = useState({});
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const toast = useRef(null);

    useEffect(() => {
        // 8:00~19:00 데이터 읽어오도록 
        const s = new Date(props.date.getFullYear(), props.date.getMonth(), props.date.getDate(), 0, 0, 10, 0);  // 오늘은 0시 이후것 읽어야 함. 
        setStart(s);
        const e = new Date(s.getFullYear(), s.getMonth(), s.getDate()+1, 0, 0, 10, 0);  // 다음날 0시 것도 읽어야 함. 
        setEnd(e);
    }, [props.date]);

    /// PV Inverter 데이터 읽어서 분석 
    useEffect(() => {
        if (!appState.mmurl) return;
        if (!start || !end) return;
        setLoading(true);
        let body = {
            o: 'obix',
            c: [
                { o: 'str', name: 'edge', val: 'obd' },
                { o: 'str', name: 'uri', val: 'pv/11/inverter' },
                { o: 'abstime', name: 'start', val: ObixUtil.toIsoDate(start) },
                { o: 'abstime', name: 'end', val: ObixUtil.toIsoDate(end) },
            ]
        };
        console.log(`start=${start}, end=${end}`);
        ObixUtil.invokeObix(appState.mmurl + "/obix/histories/history/queryTable", body, 
            (resp) => {
                const dataMap = {};
                dataMap["frequency"] = calcMinMaxAvgPv(resp, "frequency");
                dataMap["acVoltageA"] = calcMinMaxAvgPv(resp, "acVoltageA");
                dataMap["acCurrentA"] = calcMinMaxAvgPv(resp, "acCurrentA");
                dataMap["dcVoltage"] = calcMinMaxAvgPv(resp, "dcVoltage");
                dataMap["dcCurrent"] = calcMinMaxAvgPv(resp, "dcCurrent");
                dataMap["generated"] = calcMinMaxAvgPv(resp, "generated");
                setInverterMap(dataMap);
                setLoading(false);
            },
            (err) => {
                console.error(err);
                toast.current?.show({ severity: 'error', summary: 'Failed to load from mastermind', detail: err });
                setLoading(false);
            }
        );
    }, [appState.mmurl, start, end]);

    /// PCS 데이터 읽어서 분석 
    useEffect(() => {
        if (!appState.mmurl) return;
        if (!start || !end) return;
        setLoading(true);
        let body = {
            o: 'obix',
            c: [
                { o: 'str', name: 'edge', val: 'obd' },
                { o: 'str', name: 'uri', val: 'pcs/11/pcs' },
                { o: 'abstime', name: 'start', val: ObixUtil.toIsoDate(start) },
                { o: 'abstime', name: 'end', val: ObixUtil.toIsoDate(end) },
            ]
        };
        console.log(`start=${start}, end=${end}`);
        ObixUtil.invokeObix(appState.mmurl + "/obix/histories/history/queryTable", body, 
            (resp) => {
                const dataMap = {};
                dataMap["acVoltageA"] = calcMinMaxAvgPv(resp, "acVoltageA");
                dataMap["acCurrentA"] = calcMinMaxAvgPv(resp, "acCurrentA");
                dataMap["dcVoltage"] = calcMinMaxAvgPv(resp, "dcVoltage");
                dataMap["dcCurrent"] = calcMinMaxAvgPv(resp, "dcCurrent");
                setPcsMap(dataMap);
                setLoading(false);
            },
            (err) => {
                console.error(err);
                toast.current?.show({ severity: 'error', summary: 'Failed to load from mastermind', detail: err });
                setLoading(false);
            }
        );
    }, [appState.mmurl, start, end]);

    /// Meter 데이터 읽어서 분석 
    useEffect(() => {
        if (!appState.mmurl) return;
        if (!start || !end) return;
        setLoading(true);
        let body = {
            o: 'obix',
            c: [
                { o: 'str', name: 'edge', val: 'obd' },
                { o: 'str', name: 'uri', val: 'meter/1/meter/ch1' },
                { o: 'abstime', name: 'start', val: ObixUtil.toIsoDate(start) },
                { o: 'abstime', name: 'end', val: ObixUtil.toIsoDate(end) },
            ]
        };
        console.log(`start=${start}, end=${end}`);
        ObixUtil.invokeObix(appState.mmurl + "/obix/histories/history/queryTable", body, 
            (resp) => {
                const dataMap = {};
                dataMap["energy"] = calcMinMaxAvgPv(resp, "energy");
                dataMap["currentA"] = calcMinMaxAvgPv(resp, "currentA");
                dataMap["power"] = calcMinMaxAvgPv(resp, "power");
                setMeterMap(dataMap);
                setLoading(false);
            },
            (err) => {
                console.error(err);
                toast.current?.show({ severity: 'error', summary: 'Failed to load from mastermind', detail: err });
                setLoading(false);
            }
        );
    }, [appState.mmurl, start, end]);

    /// Battery 데이터 읽어서 분석 
    useEffect(() => {
        if (!appState.mmurl) return;
        if (!start || !end) return;
        setLoading(true);
        let body = {
            o: 'obix',
            c: [
                { o: 'str', name: 'edge', val: 'obd' },
                { o: 'str', name: 'uri', val: 'bat/1/bms' },
                { o: 'abstime', name: 'start', val: ObixUtil.toIsoDate(start) },
                { o: 'abstime', name: 'end', val: ObixUtil.toIsoDate(end) },
            ]
        };
        console.log(`start=${start}, end=${end}`);
        ObixUtil.invokeObix(appState.mmurl + "/obix/histories/history/queryTable", body, 
            (resp) => {
                const dataMap = {};
                dataMap["SOC"] = calcMinMaxAvgPv(resp, "SOC");
                setBatMap(dataMap);
                setLoading(false);
            },
            (err) => {
                console.error(err);
                toast.current?.show({ severity: 'error', summary: 'Failed to load from mastermind', detail: err });
                setLoading(false);
            }
        );
    }, [appState.mmurl, start, end]);    

    return (
        <div ref={ref}>
            <div className="flex align-items-center justify-content-center my-2">
                <h3>외병도태양광발전시스템일보</h3>
            </div>
            <div className="text-lg my-2">
                {props.date.toLocaleDateString('ko-KR', {weekday: "long", year: "numeric", month: "long", day: "numeric"})}
                { loading ? <i className="pi pi-spin pi-spinner ml-2 font-bold" style={{'fontSize': '1em', 'color': 'gray'}}/> : null }
            </div>
            <div className="text-lg my-2">
                날 씨: 맑음: &nbsp;&nbsp;&nbsp; 흐림: &nbsp;&nbsp;&nbsp; 눈: &nbsp;&nbsp;&nbsp; 비: &nbsp;
            </div>
            <br/>
            <table className="report">
                <thead>
                    <tr>
                        <th colSpan={2}>기계장치</th>
                        <th>단위</th>
                        <th>02</th>
                        <th>04</th>
                        <th>06</th>
                        <th>08</th>
                        <th>10</th>
                        <th>12</th>
                        <th>14</th>
                        <th>16</th>
                        <th>18</th>
                        <th>20</th>
                        <th>22</th>
                        <th>24</th>
                        <th>최소</th>
                        <th>최대</th>
                        <th>평균</th>
                        <th>전력량</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowSpan={3}>태양전지</td>
                        <td>출력전류</td>
                        <td>A</td>
                        <td>{inverterMap['dcCurrent']?.h2}</td>
                        <td>{inverterMap['dcCurrent']?.h4}</td>
                        <td>{inverterMap['dcCurrent']?.h6}</td>
                        <td>{inverterMap['dcCurrent']?.h8}</td>
                        <td>{inverterMap['dcCurrent']?.h10}</td>
                        <td>{inverterMap['dcCurrent']?.h12}</td>
                        <td>{inverterMap['dcCurrent']?.h14}</td>
                        <td>{inverterMap['dcCurrent']?.h16}</td>
                        <td>{inverterMap['dcCurrent']?.h18}</td>
                        <td>{inverterMap['dcCurrent']?.h20}</td>
                        <td>{inverterMap['dcCurrent']?.h22}</td>
                        <td>{inverterMap['dcCurrent']?.h24}</td>
                        <td>{inverterMap['dcCurrent']?.min}</td>
                        <td>{inverterMap['dcCurrent']?.max}</td>
                        <td>{inverterMap['dcCurrent'] ? printf("%.1f", inverterMap['dcCurrent'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>출력전압</td>
                        <td>V</td>
                        <td>{inverterMap['dcVoltage']?.h2}</td>
                        <td>{inverterMap['dcVoltage']?.h4}</td>
                        <td>{inverterMap['dcVoltage']?.h6}</td>
                        <td>{inverterMap['dcVoltage']?.h8}</td>
                        <td>{inverterMap['dcVoltage']?.h10}</td>
                        <td>{inverterMap['dcVoltage']?.h12}</td>
                        <td>{inverterMap['dcVoltage']?.h14}</td>
                        <td>{inverterMap['dcVoltage']?.h16}</td>
                        <td>{inverterMap['dcVoltage']?.h18}</td>
                        <td>{inverterMap['dcVoltage']?.h20}</td>
                        <td>{inverterMap['dcVoltage']?.h22}</td>
                        <td>{inverterMap['dcVoltage']?.h24}</td>
                        <td>{inverterMap['dcVoltage']?.min}</td>
                        <td>{inverterMap['dcVoltage']?.max}</td>
                        <td>{inverterMap['dcVoltage'] ? printf("%.1f", inverterMap['dcVoltage'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>발전량</td>
                        <td>kWh</td>
                        <td className="text-sm">{inverterMap['generated']?.h2 ? Math.round(inverterMap['generated']?.h2) : ""}</td>
                        <td className="text-sm">{inverterMap['generated']?.h4 ? Math.round(inverterMap['generated']?.h4) : ""}</td>
                        <td className="text-sm">{inverterMap['generated']?.h6 ? Math.round(inverterMap['generated']?.h6) : ""}</td>
                        <td className="text-sm">{inverterMap['generated']?.h8 ? Math.round(inverterMap['generated']?.h8) : ""}</td>
                        <td className="text-sm">{inverterMap['generated']?.h10 ? Math.round(inverterMap['generated']?.h10) : ""}</td>
                        <td className="text-sm">{inverterMap['generated']?.h12 ? Math.round(inverterMap['generated']?.h12) : ""}</td>
                        <td className="text-sm">{inverterMap['generated']?.h14 ? Math.round(inverterMap['generated']?.h14) : ""}</td>
                        <td className="text-sm">{inverterMap['generated']?.h16 ? Math.round(inverterMap['generated']?.h16) : ""}</td>
                        <td className="text-sm">{inverterMap['generated']?.h18 ? Math.round(inverterMap['generated']?.h18) : ""}</td>
                        <td className="text-sm">{inverterMap['generated']?.h20 ? Math.round(inverterMap['generated']?.h20) : ""}</td>
                        <td className="text-sm">{inverterMap['generated']?.h22 ? Math.round(inverterMap['generated']?.h22) : ""}</td>
                        <td className="text-sm">{inverterMap['generated']?.h24 ? Math.round(inverterMap['generated']?.h24) : ""}</td>
                        <td className="text-sm">{Math.round(inverterMap['generated']?.min)}</td>
                        <td className="text-sm">{Math.round(inverterMap['generated']?.max)}</td>
                        <td className="text-sm">{inverterMap['generated'] ? printf("%.1f", inverterMap['generated'].avg) : ""}</td>
                        <td>{inverterMap['generated'] ? printf("%.1f", inverterMap['generated'].today) : ""}</td>
                    </tr>
                    <tr>
                        <td rowSpan={6}>전력조절기</td>
                        <td>입력전압</td>
                        <td>V</td>
                        <td>{pcsMap['acVoltageA']?.h2}</td>
                        <td>{pcsMap['acVoltageA']?.h4}</td>
                        <td>{pcsMap['acVoltageA']?.h6}</td>
                        <td>{pcsMap['acVoltageA']?.h8}</td>
                        <td>{pcsMap['acVoltageA']?.h10}</td>
                        <td>{pcsMap['acVoltageA']?.h12}</td>
                        <td>{pcsMap['acVoltageA']?.h14}</td>
                        <td>{pcsMap['acVoltageA']?.h16}</td>
                        <td>{pcsMap['acVoltageA']?.h18}</td>
                        <td>{pcsMap['acVoltageA']?.h20}</td>
                        <td>{pcsMap['acVoltageA']?.h22}</td>
                        <td>{pcsMap['acVoltageA']?.h24}</td>
                        <td>{pcsMap['acVoltageA']?.min}</td>
                        <td>{pcsMap['acVoltageA']?.max}</td>
                        <td>{pcsMap['acVoltageA'] ? printf("%.1f", pcsMap['acVoltageA'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>입력전류</td>
                        <td>A</td>
                        <td>{pcsMap['acCurrentA']?.h2}</td>
                        <td>{pcsMap['acCurrentA']?.h4}</td>
                        <td>{pcsMap['acCurrentA']?.h6}</td>
                        <td>{pcsMap['acCurrentA']?.h8}</td>
                        <td>{pcsMap['acCurrentA']?.h10}</td>
                        <td>{pcsMap['acCurrentA']?.h12}</td>
                        <td>{pcsMap['acCurrentA']?.h14}</td>
                        <td>{pcsMap['acCurrentA']?.h16}</td>
                        <td>{pcsMap['acCurrentA']?.h18}</td>
                        <td>{pcsMap['acCurrentA']?.h20}</td>
                        <td>{pcsMap['acCurrentA']?.h22}</td>
                        <td>{pcsMap['acCurrentA']?.h24}</td>
                        <td>{pcsMap['acCurrentA']?.min}</td>
                        <td>{pcsMap['acCurrentA']?.max}</td>
                        <td>{pcsMap['acCurrentA'] ? printf("%.1f", pcsMap['acCurrentA'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>출력전류</td>
                        <td>A</td>
                        <td>{pcsMap['dcCurrent']?.h2}</td>
                        <td>{pcsMap['dcCurrent']?.h4}</td>
                        <td>{pcsMap['dcCurrent']?.h6}</td>
                        <td>{pcsMap['dcCurrent']?.h8}</td>
                        <td>{pcsMap['dcCurrent']?.h10}</td>
                        <td>{pcsMap['dcCurrent']?.h12}</td>
                        <td>{pcsMap['dcCurrent']?.h14}</td>
                        <td>{pcsMap['dcCurrent']?.h16}</td>
                        <td>{pcsMap['dcCurrent']?.h18}</td>
                        <td>{pcsMap['dcCurrent']?.h20}</td>
                        <td>{pcsMap['dcCurrent']?.h22}</td>
                        <td>{pcsMap['dcCurrent']?.h24}</td>
                        <td>{pcsMap['dcCurrent']?.min}</td>
                        <td>{pcsMap['dcCurrent']?.max}</td>
                        <td>{pcsMap['dcCurrent'] ? printf("%.1f", pcsMap['dcCurrent'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>축전지전류</td>
                        <td>A</td>
                        <td>{pcsMap['dcCurrent']?.h2}</td>
                        <td>{pcsMap['dcCurrent']?.h4}</td>
                        <td>{pcsMap['dcCurrent']?.h6}</td>
                        <td>{pcsMap['dcCurrent']?.h8}</td>
                        <td>{pcsMap['dcCurrent']?.h10}</td>
                        <td>{pcsMap['dcCurrent']?.h12}</td>
                        <td>{pcsMap['dcCurrent']?.h14}</td>
                        <td>{pcsMap['dcCurrent']?.h16}</td>
                        <td>{pcsMap['dcCurrent']?.h18}</td>
                        <td>{pcsMap['dcCurrent']?.h20}</td>
                        <td>{pcsMap['dcCurrent']?.h22}</td>
                        <td>{pcsMap['dcCurrent']?.h24}</td>
                        <td>{pcsMap['dcCurrent']?.min}</td>
                        <td>{pcsMap['dcCurrent']?.max}</td>
                        <td>{pcsMap['dcCurrent'] ? printf("%.1f", pcsMap['dcCurrent'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>출력전압</td>
                        <td>V</td>
                        <td>{pcsMap['dcVoltage']?.h2}</td>
                        <td>{pcsMap['dcVoltage']?.h4}</td>
                        <td>{pcsMap['dcVoltage']?.h6}</td>
                        <td>{pcsMap['dcVoltage']?.h8}</td>
                        <td>{pcsMap['dcVoltage']?.h10}</td>
                        <td>{pcsMap['dcVoltage']?.h12}</td>
                        <td>{pcsMap['dcVoltage']?.h14}</td>
                        <td>{pcsMap['dcVoltage']?.h16}</td>
                        <td>{pcsMap['dcVoltage']?.h18}</td>
                        <td>{pcsMap['dcVoltage']?.h20}</td>
                        <td>{pcsMap['dcVoltage']?.h22}</td>
                        <td>{pcsMap['dcVoltage']?.h24}</td>
                        <td>{pcsMap['dcVoltage']?.min}</td>
                        <td>{pcsMap['dcVoltage']?.max}</td>
                        <td>{pcsMap['dcVoltage'] ? printf("%.1f", pcsMap['dcVoltage'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>배터리잔량</td>
                        <td>%</td>
                        <td>{batMap['SOC']?.h2}</td>
                        <td>{batMap['SOC']?.h4}</td>
                        <td>{batMap['SOC']?.h6}</td>
                        <td>{batMap['SOC']?.h8}</td>
                        <td>{batMap['SOC']?.h10}</td>
                        <td>{batMap['SOC']?.h12}</td>
                        <td>{batMap['SOC']?.h14}</td>
                        <td>{batMap['SOC']?.h16}</td>
                        <td>{batMap['SOC']?.h18}</td>
                        <td>{batMap['SOC']?.h20}</td>
                        <td>{batMap['SOC']?.h22}</td>
                        <td>{batMap['SOC']?.h24}</td>
                        <td>{batMap['SOC']?.min}</td>
                        <td>{batMap['SOC']?.max}</td>
                        <td>{batMap['SOC'] ? printf("%.2f", batMap['SOC'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td rowSpan={3}>인버터</td>
                        <td>출력주파수</td>
                        <td>F</td>
                        <td>{inverterMap['frequency']?.h2}</td>
                        <td>{inverterMap['frequency']?.h4}</td>
                        <td>{inverterMap['frequency']?.h6}</td>
                        <td>{inverterMap['frequency']?.h8}</td>
                        <td>{inverterMap['frequency']?.h10}</td>
                        <td>{inverterMap['frequency']?.h12}</td>
                        <td>{inverterMap['frequency']?.h14}</td>
                        <td>{inverterMap['frequency']?.h16}</td>
                        <td>{inverterMap['frequency']?.h18}</td>
                        <td>{inverterMap['frequency']?.h20}</td>
                        <td>{inverterMap['frequency']?.h22}</td>
                        <td>{inverterMap['frequency']?.h24}</td>
                        <td>{inverterMap['frequency']?.min}</td>
                        <td>{inverterMap['frequency']?.max}</td>
                        <td>{inverterMap['frequency'] ? printf("%.1f", inverterMap['frequency'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>출력전압</td>
                        <td>V</td>
                        <td>{inverterMap['acVoltageA']?.h2}</td>
                        <td>{inverterMap['acVoltageA']?.h4}</td>
                        <td>{inverterMap['acVoltageA']?.h6}</td>
                        <td>{inverterMap['acVoltageA']?.h8}</td>
                        <td>{inverterMap['acVoltageA']?.h10}</td>
                        <td>{inverterMap['acVoltageA']?.h12}</td>
                        <td>{inverterMap['acVoltageA']?.h14}</td>
                        <td>{inverterMap['acVoltageA']?.h16}</td>
                        <td>{inverterMap['acVoltageA']?.h18}</td>
                        <td>{inverterMap['acVoltageA']?.h20}</td>
                        <td>{inverterMap['acVoltageA']?.h22}</td>
                        <td>{inverterMap['acVoltageA']?.h24}</td>
                        <td>{inverterMap['acVoltageA']?.min}</td>
                        <td>{inverterMap['acVoltageA']?.max}</td>
                        <td>{inverterMap['acVoltageA'] ? printf("%.1f", inverterMap['acVoltageA'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>출력전류</td>
                        <td>A</td>
                        <td>{inverterMap['acCurrentA']?.h2}</td>
                        <td>{inverterMap['acCurrentA']?.h4}</td>
                        <td>{inverterMap['acCurrentA']?.h6}</td>
                        <td>{inverterMap['acCurrentA']?.h8}</td>
                        <td>{inverterMap['acCurrentA']?.h10}</td>
                        <td>{inverterMap['acCurrentA']?.h12}</td>
                        <td>{inverterMap['acCurrentA']?.h14}</td>
                        <td>{inverterMap['acCurrentA']?.h16}</td>
                        <td>{inverterMap['acCurrentA']?.h18}</td>
                        <td>{inverterMap['acCurrentA']?.h20}</td>
                        <td>{inverterMap['acCurrentA']?.h22}</td>
                        <td>{inverterMap['acCurrentA']?.h24}</td>
                        <td>{inverterMap['acCurrentA']?.min}</td>
                        <td>{inverterMap['acCurrentA']?.max}</td>
                        <td>{inverterMap['acCurrentA'] ? printf("%.1f", inverterMap['acCurrentA'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td rowSpan={5}>배전반</td>
                        <td>소비전력량</td>
                        <td>kWh</td>
                        <td className="text-sm">{meterMap['energy']?.h2}</td>
                        <td className="text-sm">{meterMap['energy']?.h4}</td>
                        <td className="text-sm">{meterMap['energy']?.h6}</td>
                        <td className="text-sm">{meterMap['energy']?.h8}</td>
                        <td className="text-sm">{meterMap['energy']?.h10}</td>
                        <td className="text-sm">{meterMap['energy']?.h12}</td>
                        <td className="text-sm">{meterMap['energy']?.h14}</td>
                        <td className="text-sm">{meterMap['energy']?.h16}</td>
                        <td className="text-sm">{meterMap['energy']?.h18}</td>
                        <td className="text-sm">{meterMap['energy']?.h20}</td>
                        <td className="text-sm">{meterMap['energy']?.h22}</td>
                        <td className="text-sm">{meterMap['energy']?.h24}</td>
                        <td className="text-sm">{meterMap['energy']?.min}</td>
                        <td className="text-sm">{meterMap['energy']?.max}</td>
                        <td className="text-sm">{meterMap['energy'] ? Math.round(meterMap['energy'].avg) : ""}</td>
                        <td>{meterMap['energy']?.today}</td>
                    </tr>
                    <tr>
                        <td>1마을소비전류</td>
                        <td>A</td>
                        <td>{meterMap['currentA']?.h2}</td>
                        <td>{meterMap['currentA']?.h4}</td>
                        <td>{meterMap['currentA']?.h6}</td>
                        <td>{meterMap['currentA']?.h8}</td>
                        <td>{meterMap['currentA']?.h10}</td>
                        <td>{meterMap['currentA']?.h12}</td>
                        <td>{meterMap['currentA']?.h14}</td>
                        <td>{meterMap['currentA']?.h16}</td>
                        <td>{meterMap['currentA']?.h18}</td>
                        <td>{meterMap['currentA']?.h20}</td>
                        <td>{meterMap['currentA']?.h22}</td>
                        <td>{meterMap['currentA']?.h24}</td>
                        <td>{meterMap['currentA']?.min}</td>
                        <td>{meterMap['currentA']?.max}</td>
                        <td>{meterMap['currentA'] ? printf("%.1f", meterMap['currentA'].avg) : ""}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2마을소비전류</td>
                        <td>A</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>3마을소비전류</td>
                        <td>A</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>관리동소비전류</td>
                        <td>A</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>순시수요</td>
                        <td>전력</td>
                        <td>kW</td>
                        <td>{meterMap['power']?.h2}</td>
                        <td>{meterMap['power']?.h4}</td>
                        <td>{meterMap['power']?.h6}</td>
                        <td>{meterMap['power']?.h8}</td>
                        <td>{meterMap['power']?.h10}</td>
                        <td>{meterMap['power']?.h12}</td>
                        <td>{meterMap['power']?.h14}</td>
                        <td>{meterMap['power']?.h16}</td>
                        <td>{meterMap['power']?.h18}</td>
                        <td>{meterMap['power']?.h20}</td>
                        <td>{meterMap['power']?.h22}</td>
                        <td>{meterMap['power']?.h24}</td>
                        <td>{meterMap['power']?.min}<br/>({meterMap['power'] ? printf('%02d:%02d', new Date(meterMap['power'].minTime).getHours(), new Date(meterMap['power'].minTime).getMinutes()) : ""})</td>
                        <td>{meterMap['power']?.max}<br/>({meterMap['power'] ? printf('%02d:%02d', new Date(meterMap['power'].maxTime).getHours(), new Date(meterMap['power'].maxTime).getMinutes()) : ""})</td>
                        <td>{meterMap['power'] ? printf("%.1f", meterMap['power'].avg) : ""}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <div className="text-lg my-2">
                기타
            </div>
            <small>
                * 고장 및 경보발생 사항 기재
            </small>
            {/*
            <table className="report">
                <tbody>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            */}
            <Toast ref={toast}></Toast>
        </div>
    );
});

function PvTable(props) {
    return (
        <div>

        </div>
    );
}