import { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { BatteryHorizIcon, GensetIcon, HvacIcon, ImdIcon, MeterIcon, PcsIcon, PvIcon, DcdcIcon, IconLabel, HorizLine, Horiz90Line, VerticalLine } from '../service/SvgIcons';
import { ObixUtil } from '../service/ObixUtil';

export function SingleLineSvgVietnam(props) {
    // props.objmap
    // props.site
    // props.siteInfo
    const ix0 = 125;
    const ix1 = 430;  // pcs 위치 
    const ix2 = 820;
    const iy0 = 50;
    const iy1 = 300;  // pcs 위치 
    const iy2 = 385;
    const ixp = (ix1 + ix2)/2;
    const iyp = 150;
    const iw = 75;  // icon width
    const ih = 75;  // icon height
    const jx = 430;  // joint x
    const fs = 24;  // font size

    const [pcsObj, setPcsObj] = useState(null);
    const [pv1Obj, setPv1Obj] = useState(null);
    const [pv2Obj, setPv2Obj] = useState(null);
    const [pv3Obj, setPv3Obj] = useState(null);
    const [batObj, setBatObj] = useState(null);
    const [meterObj, setMeterObj] = useState(null);

    useEffect(() => {
        //console.dir(props.objmap);
        if (!props?.objmap) return;
        setPcsObj(props.objmap.get('pcs/11/pcs'));
        setPv1Obj(props.objmap.get('pv1/11/inverter'));
        setPv2Obj(props.objmap.get('pv2/11/inverter'));
        setPv3Obj(props.objmap.get('pv3/11/inverter'));
        setBatObj(props.objmap.get('bat/1/bms'));
        setMeterObj(props.objmap.get('meter/1/meter/ch1'));
    }, [props.objmap]);

    return (
        <svg width="100%" height="100%" viewBox="0 0 1024 526">
            <g>
                <HorizLine x1={ix1} x2={jx} y1={iy1+ih/2} obj={pcsObj} power="+power"/>                
                <PcsIcon x={ix1} y={iy1} w={iw} h={ih}/>
                <IconLabel x={ix1} y={iy1+ih+fs} h={fs} type="ac" obj={pcsObj}/>
                <HorizLine x1={ix2} x2={ix1+iw} y1={iy1+ih/2} obj={batObj}/>                
                <BatteryHorizIcon x={ix2} y={iy1} w={iw} h={ih}/>
                <IconLabel x={ix2} y={iy1+ih+fs} h={fs} type="bat" obj={batObj}/>

                <DcdcIcon x={ixp-iw-10} y={iyp} w={iw} h={ih}/>
                {/*<PvIcon x={ixp-iw-10} y={iyp-100} w={iw} h={ih}/>*/}
                <VerticalLine x1={ixp-iw-10+iw/2} y1={iyp+ih} y2={iy1+ih/2}/>

                <DcdcIcon x={ixp} y={iyp} w={iw} h={ih}/>
                {/*<PvIcon x={ixp} y={iyp-100} w={iw} h={ih}/>*/}
                <VerticalLine x1={ixp+iw/2} y1={iyp+ih} y2={iy1+ih/2}/>

                <DcdcIcon x={ixp+iw+10} y={iyp} w={iw} h={ih}/>
                {/*<PvIcon x={ixp+iw+10} y={iyp-100} w={iw} h={ih}/>*/}
                <VerticalLine x1={ixp+iw+10+iw/2} y1={iyp+ih} y2={iy1+ih/2}/>

                <MeterIcon x={ix0} y={iy1} w={iw} h={ih}/>
                <HorizLine x1={ix0+iw} x2={jx} y1={iy1+ih/2} obj={meterObj} power="-power"/>      
                <IconLabel x={ix0} y={iy1+ih+fs} h={fs} type="ac" obj={meterObj}/>          
            </g>
        </svg>
    );
}
