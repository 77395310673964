import { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
//import { AppContext } from '../index';
import { AppStateAtom } from '../atoms';
import { useRecoilValue } from 'recoil';
import { ObixUtil } from '../service/ObixUtil';
import { GensetIcon, HvacIcon, ImdIcon, MeterIcon, PcsIcon, PvIcon, DcdcIcon, SensorIcon, RemoteIoIcon, BatteryIcon } from '../service/SvgIcons';

export function Devices(props) {
    // props.objmap : live objmap
    // props.siteInfo
    //const appContext = useContext(AppContext);
    const [ports, setPorts] = useState([]);
    
    const devices = props.siteInfo?.devices;
    const edges = props.siteInfo?.edges;

    const rows = [];
    let edge = "???";
    if (edges) {
        edge = edges[0];
    }
    if (devices) {
        let i = 0;
        for (const d of devices) {
            rows.push(<DevicesRow key={i} row={d} objmap={props.objmap} edge={edge}/>);
            i++;
        }
    }    

    return (
        <div>
            <EssServices edge={edge}/>
            {rows}
        </div>
    )
}

export function EssServices(props) {
    // props.edge
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const [serviceComp, setServiceComp] = useState(null);

    useEffect(() => {
        if (!appState.mmurl || !props.edge) return;
        const fetchActiveService = () => {
            ObixUtil.invokeObix(`${appState.mmurl}/obix/edges/${props.edge}/obix/services/ess/getActiveService`, {}, 
                (resp) => {
                    console.dir(resp.data);
                    const service = ObixUtil.find(resp.data, "name")?.val;
                    if (service === "Microgrid") {
                        setServiceComp(<MicrogridService obj={resp.data}/>);
                    } else if (service === "PVES") {
                        setServiceComp(<PvesService obj={resp.data}/>);
                    } else {
                        setServiceComp(<NoService/>);
                    }
                }, 
                (err) => {
                    setServiceComp(<NoService/>);
                    console.error(err);
                }
            );    
        }
        const timer = setInterval(() => {
            fetchActiveService();
        }, 10*300);
        fetchActiveService();  // 10초 뒤에 실행이 되어서, 처음에는 한번 불러준다. 
        return () => clearInterval(timer);
    }, [appState.mmurl, props.edge]);

    const title = <span>
        <h5 className="p-m-0"><i className="pi pi-box"></i> ESS Service</h5>
    </span>;


    return (
        <Card className="mb-4" title={title}>
            {serviceComp}
        </Card>
    )
}

function MicrogridService(props) {
    // props.obj
    const map = ObixUtil.cmap(props.obj);
    return (
        <div className="flex">
            <Message severity="success" text="Microgrid" className="font-bold mr-2"/>
            <div className="flex align-items-center">
                <Tag severity="primary" value={`Op Range: ${map.get('lowerSOC')?.val} ~ ${map.get('upperSOC')?.val}%`} className="text-base font-normal mr-1"/>
                <Tag severity="primary" value={`Genset Range: ${map.get('genStartSOC')?.val} ~ ${map.get('genStopSOC')?.val}%`} className="text-base font-normal mr-1"/>
            </div>
        </div>
    );
}

function PvesService(props) {
    // props.obj
    const [map, setMap] = useState(new Map());
    const [opMode, setOpMode] = useState("---");
    const [dcCoupled, setDcCoupled] = useState(false);
    useEffect(() => {
        if (props.obj) {
            setMap(ObixUtil.cmap(props.obj));
        }
    }, [props.obj]);

    useEffect(() => {
        if (map) {
            setOpMode(map.get('opMode')?.val);
            setDcCoupled(map.get('dcCoupled')?.val);
        }
    }, [map])

    return (
        <div className="flex">
            <Message severity="success" text="PV+ESS" className="font-bold mr-2"/>
            {/*<Message severity="info" text={opMode} className="font-bold"/>*/}
            <div className="flex align-items-center">     
                <Tag severity={ ["charging", "discharging"].includes(opMode) ? "warning" : "info"} value={opMode} className="text-base font-bold mr-1"/>
                <Tag severity="primary" value={dcCoupled ? "DC-Coupled" : "AC-Coupled"} className="text-base font-normal mr-1"/>
                <Tag severity="primary" value={`SoC Range: ${map.get('lowerSOC')?.val} ~ ${map.get('upperSOC')?.val}%`} className="text-base font-normal mr-1"/>
                <span className="mx-2"> </span>
            </div>
        </div>
    );
}

function NoService(props) {
    return (
        <div className="flex">
            <Message severity="success" text="No Service" className="font-bold"/>
        </div>
    );
}

function DevicesRow(props) {
    // props.objmap
    // props.row: [ "url1", "url2" ]
    const devices = [];
    for (const u of props.row) {
        devices.push(<DeviceCard key={u} uri={u} objmap={props.objmap} edge={props.edge} />);
    }
    return (
        <div className="grid">{devices}</div>
    )
}

export function DeviceCard(props) {
    // props.edge: name of edge
    // props.uri: bat/1/bms ...
    // props.objmap
    // props.embedded: boolean, 다른 화면에서 임베딩되는 거냐? 
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const [name, setName] = useState("");
    const [vendor, setVendor] = useState("");
    const [model, setModel] = useState("");
    const [devType, setDevType] = useState("");
    useEffect(() => {
        if (!appState.mmurl) return;
        const body = [
            { o: 'str', name: 'edge', val: props.edge },
            { o: 'str', name: 'uri', val: props.uri }
        ];
        ObixUtil.invokeObix(`${appState.mmurl}/obix/histories/getDeviceInfo`, body, 
            (resp) => {
                //console.dir(resp.data);
                // 자식 중 str tag만 추린다. 
                const d = [];
                const attr = {};
                for (const child of resp.data.c) {
                    if (child.name === "vendor") {
                        setVendor(child.val);
                    } else if (child.name === "model") {
                        setModel(child.val);
                    } else if (child.name === "devType") {
                        setDevType(child.val);
                    } else if (child.name === "deviceName") {
                        setName(child.val);
                    }
                }
            }, 
            (err) => {
                console.error(err);
            }
        );    
    }, [appState.mmurl, props.edge, props.uri])

    const titleTemplate = <div>
        <span className="text-lg text-green-900">{name} </span><span className="text-sm font-light font-italic text-green-900">{vendor}: {model}</span>
        <span className="text-sm font-light text-green-900"> ({props.uri})</span>
    </div>;

    const prefix = props.uri;
    return (
        <div className={props.embedded === true ? "col-12" : "col-12 md:col-12 lg:col-4 xl:col-3"}>
            <Panel header={titleTemplate} className="grayheader mb-2">
                <div className="mt-3">
                    {
                        devType === "ElecMeter" ? <ElecMeterDevice prefix={prefix} objmap={props.objmap}/> :
                        devType === "PVInverter" ? <PvInverterDevice prefix={prefix} objmap={props.objmap}/> : 
                        devType === "DCDC" ? <DcdcDevice prefix={prefix} objmap={props.objmap}/> : 
                        devType === "Battery" ? <BatteryDevice prefix={prefix} objmap={props.objmap}/> : 
                        devType === "PCS" ? <PcsDevice prefix={prefix} objmap={props.objmap}/> : 
                        devType === "Genset" ? <GensetDevice prefix={prefix} objmap={props.objmap}/> :
                        devType === "EnvSensor" ? <EnvSensorDevice prefix={prefix} objmap={props.objmap}/> : 
                        devType === "HVAC" ? <HvacDevice prefix={prefix} objmap={props.objmap}/> : 
                        devType === "IsoMeter" ? <IsoDevice prefix={prefix} objmap={props.objmap}/> : 
                        devType === "RemoteIO" ? <RemoteIoDevice prefix={prefix} objmap={props.objmap}/> : 
                        <UnknownDevice prefix={prefix} objmap={props.objmap}/>
                    }
                </div>
            </Panel>            
        </div>
    );
}

// __time__ 을 기준으로 prefix로 시작하는 가장 최신 것을 얻어옴. 
function _findRecentObj(prefix, objmap) {
    //TODO: /p 와 /p 없는것 두개만 따지자. 
    let max = 0;
    let obj = undefined;
    //console.dir(objmap.keys());

    let alter = null;
    if (prefix.endsWith('/p')) {
        alter = prefix.slice(0, prefix.length - 2);
    } else {
        alter = prefix + "/p";
    }

    let t0 = null;
    if (objmap.get(prefix)) {
        t0 = objmap.get(prefix).get("__time__")?.val;
    }

    let t1 = null;
    if (objmap.get(alter)) {
        t1 = objmap.get(alter).get("__time__")?.val;
    }
    if (t0 && t1) {
        if (t0 > t1) return objmap.get(prefix);
        else return objmap.get(alter);
    } else if (t0) {
        return objmap.get(prefix);
    } else if (t1) {
        return objmap.get(alter);
    } else {
        return null;
    }    
}

function _findChild(obj, array) {
    if (!obj) return undefined;
    for (const name of array) {
        if (obj.get(name)) {
            return obj.get(name);
        }
    }
    return undefined;
}

//TODO: cbOpen 도 표현하자 
function ElecMeterDevice(props) {
    // props.prefix: acb/1/ 까지만 옴. 
    // props.objmap
    const [power, setPower] = useState("");
    const [varkw, setVarkw] = useState("");
    const [powerFactor, setPowerFactor] = useState("");
    const [hertz, setHertz] = useState("");
    const [va, setVa] = useState("");
    const [vb, setVb] = useState("");
    const [vc, setVc] = useState("");
    const [aa, setAa] = useState("");
    const [ab, setAb] = useState("");
    const [ac, setAc] = useState("");
    const [alarm, setAlarm] = useState("");
    const [warn, setWarn] = useState("");

    useEffect(() => {
        const obj = _findRecentObj(props.prefix, props.objmap);
        //TODO: 만일 error 이면 그냥 error 디스플레이 
        const powerField = _findChild(obj, ["power", "acPower"]);
        if (powerField) setPower(ObixUtil.normalizeKilo(powerField));
        const varField = _findChild(obj, ["reactivePower", "reactivePowerLag"]);
        if (varField) setVarkw(ObixUtil.normalizeKilo(varField));
        const pfField = _findChild(obj, ["powerFactor"]);
        if (pfField) setPowerFactor(ObixUtil.normalizePoint2(pfField));
        const hzField = _findChild(obj, ["frequency"]);
        if (hzField) setHertz(ObixUtil.normalizePoint2(hzField));
        const vaField = _findChild(obj, ["acVoltageAB", "voltageAB", "acVoltageA", "voltageA", "acVoltageR", "voltageR", "acVoltage", "voltage"]);
        if (vaField) setVa(ObixUtil.normalizeInt(vaField, false));
        const vbField = _findChild(obj, ["acVoltageBC", "voltageBC", "acVoltageB", "voltageB", "acVoltageS", "voltageS"]);
        if (vbField) setVb(ObixUtil.normalizeInt(vbField, false));
        const vcField = _findChild(obj, ["acVoltageCA", "voltageCA", "acVoltageC", "voltageC", "acVoltageT", "voltageT"]);
        if (vcField) setVc(ObixUtil.normalizeInt(vcField, true));
        const aaField = _findChild(obj, ["acCurrentA", "currentA", "acCurrentR", "currentR", "acCurrent", "current"]);
        if (aaField) setAa(ObixUtil.normalizePoint1(aaField, false));
        const abField = _findChild(obj, ["acCurrentB", "currentB", "acCurrentS", "currentS"]);
        if (abField) setAb(ObixUtil.normalizePoint1(abField, false));
        const acField = _findChild(obj, ["acCurrentC", "currentC", "acCurrentT", "currentT"]);
        if (acField) setAc(ObixUtil.normalizePoint1(acField, true));
        const alarmField = _findChild(obj, ["alarmList"]);
        if (alarmField) setAlarm(alarmField?.val);
        const warnField = _findChild(obj, ["warningList"]);
        if (warnField) setWarn(warnField?.val);
    }, [props.prefix, props.objmap]);

    return (
        <div>
            <div className="flex align-items-center">
                <div>
                    <div><MeterIcon w={48} color="svg-gray"/></div>
                </div>
                <div className="flex-grow-1" style={{textAlign:"center"}}>
                    <div className="text-2xl font-bold left-50">{power} / {varkw}</div>
                    <div className="text-lg">{powerFactor} / {hertz}</div>
                    <div className="text-lg">{va} / {vb} / {vc} </div>
                    <div className="text-lg">{aa} / {ab} / {ac} </div>
                </div>
            </div>
            <div>
                <div className="text-lg font-bold font-italic" style={{color:"red"}}>{alarm}</div>
                <div className="text-lg font-bold font-italic" style={{color:"orange"}}>{warn}</div>
            </div>
        </div>
    );
}

function PvInverterDevice(props) {
    // props.prefix: acb/1/ 까지만 옴. 
    // props.objmap
    const [power, setPower] = useState("");
    const [dcPower, setDcPower] = useState("");
    const [opModeIcon, setOpModeIcon] = useState("");
    const [varkw, setVarkw] = useState("");
    const [powerFactor, setPowerFactor] = useState("");
    const [dcVolt, setDcVolt] = useState("");
    const [dcAmps, setDcAmps] = useState("");
    const [va, setVa] = useState("");
    const [vb, setVb] = useState("");
    const [vc, setVc] = useState("");
    const [aa, setAa] = useState("");
    const [ab, setAb] = useState("");
    const [ac, setAc] = useState("");
    const [temp, setTemp] = useState("");
    const [alarm, setAlarm] = useState("");
    const [warn, setWarn] = useState("");

    useEffect(() => {
        const obj = _findRecentObj(props.prefix, props.objmap);
        //TODO: 만일 error 이면 그냥 error 디스플레이 
        const powerField = _findChild(obj, ["power", "acPower"]);
        if (powerField) setPower(ObixUtil.normalizeKilo(powerField));
        const dcPowerField = _findChild(obj, ["dcPower"]);
        if (dcPowerField) setDcPower(ObixUtil.normalizeKilo(dcPowerField));
        const varField = _findChild(obj, ["reactivePower", "reactivePowerLag"]);
        if (varField) setVarkw(ObixUtil.normalizeKilo(varField));
        const pfField = _findChild(obj, ["powerFactor"]);
        if (pfField) setPowerFactor(ObixUtil.normalizePoint2(pfField));
        const dcVoltField = _findChild(obj, ["dcVoltage"]);
        if (dcVoltField) setDcVolt(ObixUtil.normalizeInt(dcVoltField));
        const dcAmpsField = _findChild(obj, ["dcCurrent"]);
        if (dcAmpsField) setDcAmps(ObixUtil.normalizeInt(dcAmpsField));

        const vaField = _findChild(obj, ["acVoltageAB", "voltageAB", "acVoltageA", "voltageA", "acVoltageR", "voltageR", "acVoltage", "voltage"]);
        if (vaField) setVa(ObixUtil.normalizeInt(vaField, false));
        const vbField = _findChild(obj, ["acVoltageBC", "voltageBC", "acVoltageB", "voltageB", "acVoltageS", "voltageS"]);
        if (vbField) setVb(ObixUtil.normalizeInt(vbField, false));
        const vcField = _findChild(obj, ["acVoltageCA", "voltageCA", "acVoltageC", "voltageC", "acVoltageT", "voltageT"]);
        if (vcField) setVc(ObixUtil.normalizeInt(vcField, true));
        const aaField = _findChild(obj, ["acCurrentA", "currentA", "acCurrentR", "currentR", "acCurrent", "current"]);
        if (aaField) setAa(ObixUtil.normalizeInt(aaField, false));
        const abField = _findChild(obj, ["acCurrentB", "currentB", "acCurrentS", "currentS"]);
        if (abField) setAb(ObixUtil.normalizeInt(abField, false));
        const acField = _findChild(obj, ["acCurrentC", "currentC", "acCurrentT", "currentT"]);
        if (acField) setAc(ObixUtil.normalizeInt(acField, true));
        const tempField = _findChild(obj, ["stackTemp", "cabinetTemp", "heatsinkTemp", "igbtTemp"]);
        if (tempField) setTemp(ObixUtil.normalizePoint1(tempField));
        const alarmField = _findChild(obj, ["alarmList"]);
        if (alarmField) setAlarm(alarmField?.val);
        const warnField = _findChild(obj, ["warningList"]);
        if (warnField) setWarn(warnField?.val);
        const opField = _findChild(obj, ["opMode"]);
        const opMode = opField?.val;
        const opIcon = opMode == "charging" ? "pi pi-arrow-right" 
                        : opMode == "discharging" ? "pi pi-arrow-left"
                        : opMode == "standby" ? "pi pi-minus"
                        : opMode == "stopped" ? "pi pi-times"
                        : opMode == "islanding" ? "pi pi-sync pi-spin"
                        : "pi pi-question";
        setOpModeIcon(opIcon);
    }, [props.prefix, props.objmap]);

    return (
        <div>
            <div className="flex align-items-center">
                <div style={{textAlign:"center"}}>                    
                    <div><PvIcon w={48} color="svg-gray"/></div>
                    <i className={opModeIcon + " font-bold"} style={{'fontSize': '1.5em'}}></i>
                </div>
                <div className="flex-grow-1" style={{textAlign:"center"}}>
                    <div className="grid">
                        <div className="col-7">
                            <div className="text-2xl font-bold">{power}<span className="text-sm font-normal font-italic">AC</span></div>
                            <div className="text-lg">{va}/{vb}/{vc}</div>
                            <div className="text-lg">{aa}/{ab}/{ac}</div>     
                        </div>
                        <div className="col-5">
                            <div className="text-2xl font-bold">{dcPower}<span className="text-sm font-normal font-italic">DC</span></div>
                            <div className="text-lg">{dcVolt}</div>
                            <div className="text-lg">{dcAmps}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="text-lg font-bold font-italic" style={{color:"red"}}>{alarm}</div>
                <div className="text-lg font-bold font-italic" style={{color:"orange"}}>{warn}</div>
            </div>
        </div>
    );
}

function DcdcDevice(props) {
    // props.prefix: acb/1/ 까지만 옴. 
    // props.objmap
    const [outPower, setOutPower] = useState("");
    const [inPower, setInPower] = useState("");
    const [opModeIcon, setOpModeIcon] = useState("");
    const [inVolt, setInVolt] = useState("");
    const [inAmps, setInAmps] = useState("");
    const [outVolt, setOutVolt] = useState("");
    const [outAmps, setOutAmps] = useState("");
    const [temp, setTemp] = useState("");
    const [alarm, setAlarm] = useState("");
    const [warn, setWarn] = useState("");

    useEffect(() => {
        const obj = _findRecentObj(props.prefix, props.objmap);
        //TODO: 만일 error 이면 그냥 error 디스플레이 
        const outPowerField = _findChild(obj, ["outPower", "dcPower"]);
        if (outPowerField) setOutPower(ObixUtil.normalizeKilo(outPowerField));
        const inPowerField = _findChild(obj, ["inPower", "pvPower"]);
        if (inPowerField) setInPower(ObixUtil.normalizeKilo(inPowerField));
        const inVoltField = _findChild(obj, ["inVoltage", "inVolt", "pvVoltage", "pvVolt"]);
        if (inVoltField) setInVolt(ObixUtil.normalizeInt(inVoltField));
        const inAmpsField = _findChild(obj, ["inCurrent", "inAmps", "pvCurrent", "pvAmps"]);
        if (inAmpsField) setInAmps(ObixUtil.normalizeInt(inAmpsField));

        const outVoltField = _findChild(obj, ["outVoltage", "outVolt", "dcVoltage", "dcVolt"]);
        if (outVoltField) setOutVolt(ObixUtil.normalizeInt(outVoltField));
        const outAmpsField = _findChild(obj, ["outCurrent", "outAmps", "dcCurrent", "dcAmps"]);
        if (outAmpsField) setOutAmps(ObixUtil.normalizeInt(outAmpsField));
        const tempField = _findChild(obj, ["stackTemp", "cabinetTemp", "heatsinkTemp", "igbtTemp"]);
        if (tempField) setTemp(ObixUtil.normalizePoint1(tempField));
        const alarmField = _findChild(obj, ["alarmList"]);
        if (alarmField) setAlarm(alarmField?.val);
        const warnField = _findChild(obj, ["warningList"]);
        if (warnField) setWarn(warnField?.val);
        const opField = _findChild(obj, ["opMode"]);
        const opMode = opField?.val;
        const opIcon = opMode == "charging" ? "pi pi-arrow-right" 
                        : opMode == "discharging" ? "pi pi-arrow-left"
                        : opMode == "standby" ? "pi pi-minus"
                        : opMode == "stopped" ? "pi pi-times"
                        : opMode == "islanding" ? "pi pi-sync pi-spin"
                        : "pi pi-question";
        setOpModeIcon(opIcon);
    }, [props.prefix, props.objmap]);

    return (
        <div>
            <div className="flex align-items-center">
                <div style={{textAlign:"center"}}>                    
                    <div><DcdcIcon w={48} color="svg-gray"/></div>
                    <i className={opModeIcon + " font-bold"} style={{'fontSize': '1.5em'}}></i>
                </div>
                <div className="flex-grow-1" style={{textAlign:"center"}}>
                    <div className="grid">
                        <div className="col-7">
                            <div className="text-2xl font-bold">{outPower}<span className="text-sm font-normal font-italic">OUT</span></div>
                            <div className="text-lg">{outVolt}</div>
                            <div className="text-lg">{outAmps}</div>     
                        </div>
                        <div className="col-5">
                            <div className="text-2xl font-bold">{inPower}<span className="text-sm font-normal font-italic">IN</span></div>
                            <div className="text-lg">{inVolt}</div>
                            <div className="text-lg">{inAmps}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="text-lg font-bold font-italic" style={{color:"red"}}>{alarm}</div>
                <div className="text-lg font-bold font-italic" style={{color:"orange"}}>{warn}</div>
            </div>
        </div>
    );
}

function BatteryDevice(props) {
    // props.prefix: acb/1/ 까지만 옴. 
    // props.objmap
    const obj = _findRecentObj(props.prefix, props.objmap);
    const socField = _findChild(obj, ["SOC"]);
    const soc = ObixUtil.normalizePoint1(socField);
    const sohField = _findChild(obj, ["SOH"]);
    const soh = ObixUtil.normalizePoint1(sohField);
    const dcpowField = _findChild(obj, ["dcPower", "batPower"]);
    const dcPower = ObixUtil.normalizeKilo(dcpowField);
    const rackCountField = _findChild(obj, ["rackCount"]);
    const rackCount = ObixUtil.normalizeInt(rackCountField, false);
    const rackSwitchOnField = _findChild(obj, ["rackSwitchOn"]);
    const rackSwitchOn = ObixUtil.normalizeInt(rackSwitchOnField, false);
    const rackOnlineField = _findChild(obj, ["rackOnline"]);
    const rackOnline = ObixUtil.normalizeInt(rackOnlineField, false);
    const dcVoltField = _findChild(obj, ["dcVoltage", "batVoltage"]);
    const dcVolt = ObixUtil.normalizeInt(dcVoltField);
    const dcAmpsField = _findChild(obj, ["dcCurrent", "batCurrent"]);
    const dcAmps = ObixUtil.normalizeInt(dcAmpsField);
    const minTempField = _findChild(obj, ["minCellTemp", "minModuleTemp"]);
    const minTemp = ObixUtil.normalizePoint1(minTempField, false);
    const maxTempField = _findChild(obj, ["maxCellTemp", "maxModuleTemp"]);
    const maxTemp = ObixUtil.normalizePoint1(maxTempField, true);
    const minVoltField = _findChild(obj, ["minCellVoltage", "minCellVolt"]);
    const minVolt = ObixUtil.normalizePoint3(minVoltField, false);
    const maxVoltField = _findChild(obj, ["maxCellVoltage", "maxCellVolt"]);
    const maxVolt = ObixUtil.normalizePoint3(maxVoltField, true);
    const opField = _findChild(obj, ["opMode"]);
    const opMode = opField?.val;
    const opModeIcon = opMode == "charging" ? "pi pi-arrow-right" 
                    : opMode == "discharging" ? "pi pi-arrow-left"
                    : opMode == "standby" ? "pi pi-minus"
                    : opMode == "stopped" ? "pi pi-times"
                    : "pi pi-question";

    const alarm = _findChild(obj, ["alarmList"])?.val;
    const warn = _findChild(obj, ["warningList"])?.val;

    return (
        <div>
            <div className="flex align-items-center">
                <div style={{textAlign:"center"}}>                    
                    <div><BatteryIcon w={40} color="svg-gray"/></div>
                    <i className={opModeIcon + " font-bold"} style={{'fontSize': '1.5em'}}></i>
                </div>
                <div className="flex-grow-1" style={{textAlign:"center"}}>
                    <div className="text-2xl font-bold left-50">SOC {soc} / {dcPower}</div>
                    <div className="text-lg">{dcVolt} / {dcAmps}</div>
                    <div className="text-lg">SOH {soh} / Racks {rackOnline}:{rackSwitchOn}:{rackCount}</div>
                    <div className="text-lg">{minTemp}~{maxTemp} / {minVolt}~{maxVolt}</div>                    
                </div>
            </div>
            <div>
                <div className="text-lg font-bold font-italic" style={{color:"red"}}>{alarm}</div>
                <div className="text-lg font-bold font-italic" style={{color:"orange"}}>{warn}</div>
            </div>
        </div>
    );
}

function PcsDevice(props) {
    // props.prefix: acb/1/ 까지만 옴. 
    // props.objmap
    const [power, setPower] = useState("");
    const [dcPower, setDcPower] = useState("");
    const [opModeIcon, setOpModeIcon] = useState("");
    const [varkw, setVarkw] = useState("");
    const [powerFactor, setPowerFactor] = useState("");
    const [dcVolt, setDcVolt] = useState("");
    const [dcAmps, setDcAmps] = useState("");
    const [va, setVa] = useState("");
    const [vb, setVb] = useState("");
    const [vc, setVc] = useState("");
    const [aa, setAa] = useState("");
    const [ab, setAb] = useState("");
    const [ac, setAc] = useState("");
    const [temp, setTemp] = useState("");
    const [alarm, setAlarm] = useState("");
    const [warn, setWarn] = useState("");

    useEffect(() => {
        const obj = _findRecentObj(props.prefix, props.objmap);
        //TODO: 만일 error 이면 그냥 error 디스플레이 
        const powerField = _findChild(obj, ["power", "acPower"]);
        if (powerField) setPower(ObixUtil.normalizeKilo(powerField));
        const dcPowerField = _findChild(obj, ["dcPower"]);
        if (dcPowerField) setDcPower(ObixUtil.normalizeKilo(dcPowerField));
        const varField = _findChild(obj, ["reactivePower", "reactivePowerLag"]);
        if (varField) setVarkw(ObixUtil.normalizeKilo(varField));
        const pfField = _findChild(obj, ["powerFactor"]);
        if (pfField) setPowerFactor(ObixUtil.normalizePoint2(pfField));
        const dcVoltField = _findChild(obj, ["dcVoltage"]);
        if (dcVoltField) setDcVolt(ObixUtil.normalizeInt(dcVoltField));
        const dcAmpsField = _findChild(obj, ["dcCurrent"]);
        if (dcAmpsField) setDcAmps(ObixUtil.normalizeInt(dcAmpsField));

        const vaField = _findChild(obj, ["acVoltageAB", "voltageAB", "acVoltageA", "voltageA", "acVoltageR", "voltageR", "acVoltage", "voltage"]);
        if (vaField) setVa(ObixUtil.normalizeInt(vaField, false));
        const vbField = _findChild(obj, ["acVoltageBC", "voltageBC", "acVoltageB", "voltageB", "acVoltageS", "voltageS"]);
        if (vbField) setVb(ObixUtil.normalizeInt(vbField, false));
        const vcField = _findChild(obj, ["acVoltageCA", "voltageCA", "acVoltageC", "voltageC", "acVoltageT", "voltageT"]);
        if (vcField) setVc(ObixUtil.normalizeInt(vcField, true));
        const aaField = _findChild(obj, ["acCurrentA", "currentA", "acCurrentR", "currentR", "acCurrent", "current"]);
        if (aaField) setAa(ObixUtil.normalizeInt(aaField, false));
        const abField = _findChild(obj, ["acCurrentB", "currentB", "acCurrentS", "currentS"]);
        if (abField) setAb(ObixUtil.normalizeInt(abField, false));
        const acField = _findChild(obj, ["acCurrentC", "currentC", "acCurrentT", "currentT"]);
        if (acField) setAc(ObixUtil.normalizeInt(acField, true));
        const tempField = _findChild(obj, ["stackTemp", "cabinetTemp", "heatsinkTemp", "igbtTemp"]);
        if (tempField) setTemp(ObixUtil.normalizePoint1(tempField));
        const alarmField = _findChild(obj, ["alarmList"]);
        if (alarmField) setAlarm(alarmField?.val);
        const warnField = _findChild(obj, ["warningList"]);
        if (warnField) setWarn(warnField?.val);
        const opField = _findChild(obj, ["opMode"]);
        const opMode = opField?.val;
        const opIcon = opMode == "charging" ? "pi pi-arrow-right" 
                        : opMode == "discharging" ? "pi pi-arrow-left"
                        : opMode == "standby" ? "pi pi-minus"
                        : opMode == "stopped" ? "pi pi-times"
                        : opMode == "islanding" ? "pi pi-sync pi-spin"
                        : "pi pi-question";
        setOpModeIcon(opIcon);
    }, [props.prefix, props.objmap]);

    return (
        <div>
            <div className="flex align-items-center">
                <div style={{textAlign:"center"}}>                    
                    <div><PcsIcon w={48} color="svg-gray"/></div>
                    <i className={opModeIcon + " font-bold"} style={{'fontSize': '1.5em'}}></i>
                </div>
                <div className="flex-grow-1" style={{textAlign:"center"}}>
                    <div className="grid">
                        <div className="col-7">
                            <div className="text-2xl font-bold">{power}<span className="text-sm font-normal font-italic">AC</span></div>
                            <div className="text-lg">{va}/{vb}/{vc}</div>
                            <div className="text-lg">{aa}/{ab}/{ac}</div>     
                            <div className="text-lg">{varkw} / {powerFactor}</div>
                        </div>
                        <div className="col-5">
                            <div className="text-2xl font-bold">{dcPower}<span className="text-sm font-normal font-italic">DC</span></div>
                            <div className="text-lg">{dcVolt}</div>
                            <div className="text-lg">{dcAmps}</div>
                            <div className="text-lg">{temp}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="text-lg font-bold font-italic" style={{color:"red"}}>{alarm}</div>
                <div className="text-lg font-bold font-italic" style={{color:"orange"}}>{warn}</div>
            </div>
        </div>
    );
}

function GensetDevice(props) {
    // props.prefix: acb/1/ 까지만 옴. 
    // props.objmap
    const [power, setPower] = useState("");
    const [opModeIcon, setOpModeIcon] = useState("");
    const [varkw, setVarkw] = useState("");
    const [powerFactor, setPowerFactor] = useState("");
    const [hertz, setHertz] = useState("");
    const [va, setVa] = useState("");
    const [vb, setVb] = useState("");
    const [vc, setVc] = useState("");
    const [aa, setAa] = useState("");
    const [ab, setAb] = useState("");
    const [ac, setAc] = useState("");
    const [rpm, setRpm] = useState("");
    const [alarm, setAlarm] = useState("");
    const [warn, setWarn] = useState("");

    useEffect(() => {
        const obj = _findRecentObj(props.prefix, props.objmap);
        //TODO: 만일 error 이면 그냥 error 디스플레이 
        const powerField = _findChild(obj, ["power", "acPower"]);
        if (powerField) setPower(ObixUtil.normalizeKilo(powerField));
        const varField = _findChild(obj, ["reactivePower", "reactivePowerLag"]);
        if (varField) setVarkw(ObixUtil.normalizeKilo(varField));
        const pfField = _findChild(obj, ["powerFactor"]);
        if (pfField) setPowerFactor(ObixUtil.normalizePoint2(pfField));
        const hzField = _findChild(obj, ["frequency", "frequencyA"]);
        if (hzField) setHertz(ObixUtil.normalizePoint2(hzField));
        const vaField = _findChild(obj, ["acVoltageAB", "voltageAB", "acVoltageA", "voltageA", "acVoltageR", "voltageR", "acVoltage", "voltage"]);
        if (vaField) setVa(ObixUtil.normalizeInt(vaField, false));
        const vbField = _findChild(obj, ["acVoltageBC", "voltageBC", "acVoltageB", "voltageB", "acVoltageS", "voltageS"]);
        if (vbField) setVb(ObixUtil.normalizeInt(vbField, false));
        const vcField = _findChild(obj, ["acVoltageCA", "voltageCA", "acVoltageC", "voltageC", "acVoltageT", "voltageT"]);
        if (vcField) setVc(ObixUtil.normalizeInt(vcField, true));
        const aaField = _findChild(obj, ["acCurrentA", "currentA", "acCurrentR", "currentR", "acCurrent", "current"]);
        if (aaField) setAa(ObixUtil.normalizeInt(aaField, false));
        const abField = _findChild(obj, ["acCurrentB", "currentB", "acCurrentS", "currentS"]);
        if (abField) setAb(ObixUtil.normalizeInt(abField, false));
        const acField = _findChild(obj, ["acCurrentC", "currentC", "acCurrentT", "currentT"]);
        if (acField) setAc(ObixUtil.normalizeInt(acField, true));
        const rpmField = _findChild(obj, ["rpm"]);
        if (rpmField) setRpm(ObixUtil.normalizeInt(rpmField));
        const alarmField = _findChild(obj, ["alarmList"]);
        if (alarmField) setAlarm(alarmField?.val);
        const warnField = _findChild(obj, ["warningList"]);
        if (warnField) setWarn(warnField?.val);
        const opField = _findChild(obj, ["opMode"]);
        const opMode = opField?.val;
        const opIcon = opMode == "charging" ? "pi pi-arrow-right" 
                        : opMode == "discharging" ? "pi pi-arrow-left"
                        : opMode == "standby" ? "pi pi-minus"
                        : opMode == "stopped" ? "pi pi-times"
                        : opMode == "islanding" ? "pi pi-sync pi-spin"
                        : "pi pi-question";
        setOpModeIcon(opIcon);
    }, [props.prefix, props.objmap]);

    return (
        <div>
            <div className="flex align-items-center">
                <div style={{textAlign:"center"}}>                    
                    <div><GensetIcon w={48} color="svg-gray"/></div>
                    <i className={opModeIcon + " font-bold"} style={{'fontSize': '1.5em'}}></i>
                </div>
                <div className="flex-grow-1" style={{textAlign:"center"}}>
                    <div className="text-2xl font-bold left-50">{power} / {varkw}</div>
                    <div className="text-lg">{powerFactor} / {hertz} / {rpm}</div>
                    <div className="text-lg">{va} / {vb} / {vc} </div>
                    <div className="text-lg">{aa} / {ab} / {ac} </div>
                </div>
            </div>
            <div>
                <div className="text-lg font-bold font-italic" style={{color:"red"}}>{alarm}</div>
                <div className="text-lg font-bold font-italic" style={{color:"orange"}}>{warn}</div>
            </div>
        </div>
    );
}

function EnvSensorDevice(props) {
    // props.prefix: acb/1/ 까지만 옴. 
    // props.objmap
    const [temp, setTemp] = useState("");
    const [humid, setHumid] = useState("");
    const [alarm, setAlarm] = useState("");
    const [warn, setWarn] = useState("");
    const [insol, setInsol] = useState("");
    const [hasTempHumid, setHasTempHumid] = useState(false);
    const [tempRange, setTempRange] = useState(null);
    const [humidRange, setHumidRange] = useState(null);

    useEffect(() => {
        const obj = _findRecentObj(props.prefix, props.objmap);
        const tempField = _findChild(obj, ["temperature"]);
        if (tempField) {
            setTemp(ObixUtil.normalizePoint1(tempField));                    
        }
        const humiField = _findChild(obj, ["humidity"]);
        if (humiField) {
            setHumid(ObixUtil.normalizePoint1(humiField));            
        }
        setHasTempHumid(tempField || humiField);
        const insolField = _findChild(obj, ["insolation"]);
        if (insolField) setInsol(ObixUtil.normalizePoint1(insolField));
        const tempRange1 = _findChild(obj, ["tempLowerLimit"]);
        const tempRange2 = _findChild(obj, ["tempUpperLimit"]);
        setTempRange(`${ObixUtil.normalizePoint1(tempRange1, false)}~${ObixUtil.normalizePoint1(tempRange2)}`);
        const humidRange1 = _findChild(obj, ["humLowerLimit"]);
        const humidRange2 = _findChild(obj, ["humUpperLimit"]);
        setHumidRange(`${ObixUtil.normalizePoint1(humidRange1, false)}~${ObixUtil.normalizePoint1(humidRange2)}`);
        setAlarm(_findChild(obj, ["alarmList"])?.val);
        setWarn(_findChild(obj, ["warningList"])?.val);    
    }, [props.prefix, props.objmap]);

    return (
        <div>
            <div className="flex align-items-center">
                <div style={{textAlign:"center"}}>                    
                    <div><SensorIcon w={36} color="svg-gray"/></div>
                </div>
                <div className="flex-grow-1" style={{textAlign:"center"}}>
                    { hasTempHumid ? <div className="text-2xl font-bold left-50">{temp} / {humid}</div> : <div/> }
                    { hasTempHumid ? <div className="text-lg"><span className="text-sm font-normal font-italic mr-1">RANGE</span> {tempRange} / {humidRange}</div> : <div/> }
                    <div className="text-2xl font-bold left-50">{insol}</div>
                </div>
            </div>
            <div>
                <div className="text-lg font-bold font-italic" style={{color:"red"}}>{alarm}</div>
                <div className="text-lg font-bold font-italic" style={{color:"orange"}}>{warn}</div>
            </div>
        </div>
    );
}

function HvacDevice(props) {
    // props.prefix: acb/1/ 까지만 옴. 
    // props.objmap
    const [temp, setTemp] = useState("");
    const [humi, setHumi] = useState("");
    const [tempSet, setTempSet] = useState("");
    const [humiSet, setHumiSet] = useState("");
    const [alarm, setAlarm] = useState("");
    const [warn, setWarn] = useState("");
    const [opModeIcon, setOpModeIcon] = useState("");
    const [opModeColor, setOpModeColor] = useState(null);

    useEffect(() => {
        const obj = _findRecentObj(props.prefix, props.objmap);
        const tempField = _findChild(obj, ["temperature"]);
        setTemp(ObixUtil.normalizePoint1(tempField));
        const humiField = _findChild(obj, ["humidity"]);
        setHumi(ObixUtil.normalizePoint1(humiField));
        const tempSetField = _findChild(obj, ["setTemp", "tempSet"]);
        setTempSet(ObixUtil.normalizePoint1(tempSetField));
        const humiSetField = _findChild(obj, ["setHumid", "humidSet"]);
        setHumiSet(ObixUtil.normalizePoint1(humiSetField));
        setAlarm(_findChild(obj, ["alarmList"])?.val);
        setWarn(_findChild(obj, ["warningList"])?.val);
        const opField = _findChild(obj, ["opMode"]);
        const opMode = opField?.val;
        const opIcon = ["cooling", "heating"].includes(opMode) ? "pi pi-arrow-left" 
                        : opMode === "dehumidify" ? "pi pi-arrow-right"
                        : opMode === "standby" ? "pi pi-minus"
                        : opMode === "stopped" ? "pi pi-times"
                        : opMode === "ventilate" ? "pi pi-sync pi-spin"
                        : "pi pi-question";
        setOpModeIcon(opIcon);
        if (opMode === "cooling") {
            setOpModeColor({color: "blue"});
        } else if (opMode === "heating") {
            setOpModeColor({color: "red"});
        } else {
            setOpModeColor({});
        }
    }, [props.prefix, props.objmap]);

    return (
        <div>
            <div className="flex align-items-center">
                <div style={{textAlign:"center"}}>                    
                    <div><HvacIcon w={48} color="svg-gray"/></div>
                    <i className={opModeIcon + " font-bold text-2xl"} style={opModeColor}></i>
                </div>
                <div className="flex-grow-1" style={{textAlign:"center"}}>
                    <div className="text-2xl font-bold left-50">{temp} / {humi}</div>
                    <div className="text-lg"><span className="text-sm font-normal font-italic mr-1">SET</span> {tempSet} / {humiSet}</div>
                </div>
            </div>
            <div>
                <div className="text-lg font-bold font-italic" style={{color:"red"}}>{alarm}</div>
                <div className="text-lg font-bold font-italic" style={{color:"orange"}}>{warn}</div>
            </div>
        </div>
    );
}

function IsoDevice(props) {
    // props.prefix: acb/1/ 까지만 옴. 
    // props.objmap
    const obj = _findRecentObj(props.prefix, props.objmap);
    const isoField = _findChild(obj, ["insulation"]);
    const iso = ObixUtil.normalizeKilo(isoField);
    const minField = _findChild(obj, ["insulationMin"]);
    const min = ObixUtil.normalizeKilo(minField);
    const alarm = _findChild(obj, ["alarmList"])?.val;
    const warn = _findChild(obj, ["warningList"])?.val;

    return (
        <div>
            <div className="flex align-items-center">
                <div style={{textAlign:"center"}}>                    
                    <div><ImdIcon w={48} color="svg-gray"/></div>
                </div>
                <div className="flex-grow-1" style={{textAlign:"center"}}>
                    <div className="text-2xl font-bold left-50">{iso}</div>
                    <div className="text-lg">Min {min}</div>
                </div>
            </div>
            <div>
                <div className="text-lg font-bold font-italic" style={{color:"red"}}>{alarm}</div>
                <div className="text-lg font-bold font-italic" style={{color:"orange"}}>{warn}</div>
            </div>
        </div>
    );
}

function RemoteIoDevice(props) {
    // props.prefix: acb/1/ 까지만 옴. 
    // props.objmap
    //TODO: 구현해야 함 
    const [din, setDin] = useState([]);
    const [dout, setDout] = useState([]);
    const [alarm, setAlarm] = useState("");
    const [warn, setWarn] = useState("");
    const [dinWidget, setDinWidget] = useState(null);
    const [doutWidget, setDoutWidget] = useState(null);
    useEffect(() => {
        const obj = _findRecentObj(props.prefix, props.objmap);
        // get Din Count
        const dinRe = /di\d+/;
        const doutRe = /do\d+/;
        const dinArray = [];
        const doutArray = [];
        if (obj?.keys()) {
            for (const k of obj.keys()) {
                if (dinRe.test(k)) dinArray.push(obj.get(k)?.val);
                if (doutRe.test(k)) doutArray.push(obj.get(k)?.val);
            }
            if (dinArray.length > 0) setDin(dinArray);
            if (doutArray.length > 0) setDout(doutArray);
        }
        setAlarm(_findChild(obj, ["alarmList"])?.val);
        setWarn(_findChild(obj, ["warningList"])?.val);
    }, [props.prefix, props.objmap]);

    useEffect(() => {
        const dw = [];
        for (const d of din) {
            if (d) dw.push(<i className="pi pi-circle-fill mr-1"/>);
            else dw.push(<i className="pi pi-circle mr-1"/>);
        }
        setDinWidget(dw);
    }, [din]);

    useEffect(() => {
        const dw = [];
        for (const d of dout) {
            if (d) dw.push(<i className="pi pi-circle-fill mr-1"/>);
            else dw.push(<i className="pi pi-circle mr-1"/>);
        }
        setDoutWidget(dw);
    }, [dout]);
    
    return (
        <div>
            <div className="flex align-items-center">
                <div style={{textAlign:"center"}}>                    
                    <div><RemoteIoIcon w={48} color="svg-gray"/></div>
                </div>
                <div className="flex-grow-1 ml-4" style={{textAlign:"center"}}>
                    { dinWidget?.length ? <div><span className="text-sm font-normal font-italic mr-1">IN</span>{dinWidget}</div> : null }
                    { doutWidget?.length ? <div><span className="text-sm font-normal font-italic mr-1">OUT</span>{doutWidget}</div> : null }
                    <div className="text-2xl font-bold left-50"></div>
                    <div className="text-lg"></div>
                </div>
            </div>
            <div>
                <div className="text-lg font-bold font-italic" style={{color:"red"}}>{alarm}</div>
                <div className="text-lg font-bold font-italic" style={{color:"orange"}}>{warn}</div>
            </div>
        </div>
    );
}

function UnknownDevice(props) {
    // props.prefix: acb/1/ 까지만 옴. 
    // props.objmap
    const obj = _findRecentObj(props.prefix, props.objmap);

    const alarm = _findChild(obj, ["alarmList"])?.val;
    const warn = _findChild(obj, ["warningList"])?.val;

    return (
        <div>
            <div className="flex align-items-center">
                <div>
                    <i className="pi pi-question-circle text-2xl"/>
                </div>
                <div className="flex-grow-1" style={{textAlign:"center"}}>
                </div>
            </div>
            <div>
                <div className="text-lg font-bold font-italic" style={{color:"red"}}>{alarm}</div>
                <div className="text-lg font-bold font-italic" style={{color:"orange"}}>{warn}</div>
            </div>
        </div>
    );
}