import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import AppTopbar from './AppTopbar';
import AppMenu from './AppMenu';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { Dashboard } from './pages/Dashboard';
import { Devices } from './pages/Devices';
import { Monitor } from './pages/Monitor';
import { Events } from './pages/Events';
import { Controls } from './pages/Controls';
import { ObixStudio } from './pages/ObixStudio';
import { Report } from './pages/Report';
import { Settings } from './pages/Settings';
import { AdminUsers } from './pages/AdminUsers';
import { Login } from './pages/Login';
import { Trends } from './pages/Trends';
//import { AuthContext } from './index';
//import { AppContext } from './index';
import { ObixUtil } from './service/ObixUtil';
//NOTE: mqtt 5.0 으로 올렸더니, wss://ems.ddmix.biz 로 연결할 때 에러남???? 왜 그런지 모르겠음.
// mqtt 4를 쓰자니, webpack 5.0 이상에서 에러남.
// 그래서 precompiled-mqtt를 사용함.
//import mqtt from "mqtt"; // import namespace "mqtt"
import mqtt from "precompiled-mqtt";
import './assets/dark';  // eCharts의 dark theme 임.

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import './index.css';
import { Statistics } from './pages/Statistics';
import { useRecoilValue, useRecoilState } from 'recoil';
import { AuthStateAtom, SiteInfoAtom, SiteIdAtom, AppStateAtom, ColorModeAtom } from './atoms';

//export const ColorModeContext = React.createContext();

const App = () => {
    //const authContext = useContext(AuthContext);
    const auth = useRecoilValue(AuthStateAtom);
    const [colorMode, setColorMode] = useRecoilState(ColorModeAtom);
    const [siteInfo, setSiteInfo] = useRecoilState(SiteInfoAtom);
    const [siteId, setSiteId] = useRecoilState(SiteIdAtom);
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const [menuMode, setMenuMode] = useState('static');
    //const [colorMode, setColorMode] = useState(window.localStorage['mmColorMode'] || 'light');  // or 'dark'
    const [inlineMenuPosition, setInlineMenuPosition] = useState('bottom');
    const [desktopMenuActive, setDesktopMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');  // useState('filled');
    const [ripple, setRipple] = useState(true);
    const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
    const [menuTheme, setMenuTheme] = useState('bluegrey');  // useState('light');
    const [topbarTheme, setTopbarTheme] = useState('bluegrey'); //useState('blue');
    const [theme, setTheme] = useState('pink');  // useState('indigo');
    const [isInputBackgroundChanged, setIsInputBackgroundChanged] = useState(false);
    const [inlineMenuActive, setInlineMenuActive] = useState({});
    const [newThemeLoaded, setNewThemeLoaded] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [notifyObj, setNotifyObj] = useState(null);
    const [regions, setRegions] = useState({});  // region 전체
    //const [region, setRegion] = useState("");  // 선택된 region
    //const [siteInfo, setSiteInfo] = useState({});  // region에 해당하는 siteInfo
    const [mqttClient, setMqttClient] = useState(null);
    const [connected, setConnected] = useState(false);  // MQ가 연결되었나?
    const [alarmList, setAlarmList] = useState(new Map());  // MQ로부터 지금 받은 알람
    const [warningList, setWarningList] = useState(new Map());  // MQ로부터 지금 받은 워닝
    const [obixObj, setObixObj] = useState(null);  // MQ로 부터 지금 받은 것
    const [received, setReceived] = useState(0);  // MQ에서 받을때마다 증가. Blink 를 위해서
    const [objMap, setObjMap] = useState(new Map());  // MQ로 전해진 전체 Map 상태

    const copyTooltipRef = useRef();
    let currentInlineMenuKey = useRef(null);
    const location = useLocation();

    PrimeReact.ripple = true;

    // 로그인 안된 상태이면 로그인으로 넘긴다.
    const token = window.localStorage["mm_token"];
    if (!token) window.location.href = "/login";

    let searchClick;
    let topbarItemClick;
    let menuClick;
    let inlineMenuClick;

    const menu = [
        {
            label: "Aggregates DER", icon: 'pi pi-fw pi-home',
            items: [
                { label: 'Dashboard', icon: 'pi pi-fw pi-th-large', to: '/dashboard' },
                { label: 'Devices', icon: 'pi pi-fw pi-sitemap', to: '/devices' },
                //{ label: 'Single Line', icon: 'pi pi-fw pi-ticket', to: '/sko-singleline' },
                { label: 'Monitor', icon: 'pi pi-fw pi-eye', to: '/monitor' },
                { label: 'Trends', icon: 'pi pi-fw pi-chart-line', to: '/trends' },
                { label: 'Statistics', icon: 'pi pi-fw pi-chart-bar', to: '/statistics' },
                //{ label: 'Scheduler', icon: 'pi pi-fw pi-clock', to: '/scheduler' },
                { label: 'Events', icon: 'pi pi-fw pi-flag', to: '/events' },
                { label: 'Report', icon: 'pi pi-fw pi-file', to: '/report' },
                { label: 'Controls', icon: 'pi pi-fw pi-forward', to: '/controls' },
                { label: 'Settings', icon: 'pi pi-fw pi-cog', to: '/settings' },
                //{ label: 'Login', icon: 'pi pi-fw pi-cog', to: '/login' },
            ]
        }
    ];
    // Admin에만 해당하는 메뉴를 추가한다.
    if (auth?.isAdmin) {
        menu.push({
            label: "ADMIN", icon: 'pi pi-fw pi-home',
            items: [
                { label: 'Studio', icon: 'pi pi-fw pi-check-square', to: '/studio' },
                { label: 'Manage Users', icon: 'pi pi-fw pi-users', to: '/admin-users' }
            ]
        });
    }

    // 함수가 하위 컴포넌트로 전달될 때는 useCallback을 쓰는 것이 좋음.
    // https://www.daleseo.com/react-hooks-use-callback/
    // useCallback을 안 쓰면 setNotify 함수는 계속 새로 만들어지고, 계속 갱신이 된다.
    const setNotify = useCallback((notify) => {
        setNotifyObj(notify);
    }, []);

    /*
    const setSite = useCallback((site) => {
        setRegion(site);
        window.localStorage["mm_site"] = site;
    });
    */
    // Regions를 읽어오자
    useEffect(() => {
        if (!appState.mmurl) return;
        //console.dir(appContext);
        let invokeUrl = appState.mmurl + "/obix/about/getMyRegions";
        let body = {
        }

        ObixUtil.invokeObix(invokeUrl, body,
            (resp) => {
                console.dir(resp.data);
                const rs = {};
                if (resp.data.c) {
                    for (const child of resp.data.c) {
                        rs[child.name] = child.val;
                    }
                    setRegions(rs);
                    //const site = window.localStorage["mm_site"];
                    const site = `${siteId}`;  // 새로운 string으로 만들어서 setSiteId 해야, 아래 useEffect에서 siteIdInfo 읽어옴
                    if (site && rs[site]) {
                        setSiteId(site);
                    } else {
                        setSiteId(resp.data.c[0].name);
                    }
                    //menu[0].label = rs[resp.data.c[0].name];  // 좌측 메뉴 상단에 현재 사이트 표시
                } else {
                    setRegions({});
                    setSiteId("");
                    //menu[0].label = "<None Selected>";  // 좌측 메뉴 상단에 현재 사이트 표시
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }, [appState.mmurl]);

    // siteInfo 가져오기
    useEffect(() => {
        if (!siteId) return;
        let invokeUrl = appState.mmurl + "/obix/sites/getSiteInfo";
        let body = {
            o: 'obix',
            c: [
                { o: 'str', name: 'site', val: siteId }
            ]
        }

        ObixUtil.invokeObix(invokeUrl, body,
            (resp) => {
                console.log(`siteInfo loaded`);
                console.dir(resp.data);
                setSiteInfo(resp.data);
            },
            (err) => {
                console.error(err);
            }
        );

    }, [siteId, appState.mmurl]);

    // MQTT로 상태 받음
    useEffect(() => {
        //TODO: reconnect시 Websocket 연결을 자꾸 시도???
        //TODO: site에 edge가 여러개 있을땐 어떻게해? 일단은 첫번째 것만 받도록 하자
        if (!appState.mmurl) return;
        if (!siteInfo || !siteInfo.edges) return;
        //console.dir(appContext);
        let invokeUrl = appState.mmurl + "/obix/about/getMqInfo";
        let body = {};

        // 사이트 변경시 기존 MQ도 유지되어서 두 정보가 섞이는 문제 해결
        if (mqttClient) {
            mqttClient.end(true);  // force quit
            setObjMap(new Map());
        }

        ObixUtil.invokeObix(invokeUrl, body,
            (resp) => {
                //console.dir(resp.data);
                const mqUrl = ObixUtil.find(resp.data, "url")?.val;
                const user = ObixUtil.find(resp.data, "user")?.val;
                const pwd = ObixUtil.find(resp.data, "pwd")?.val;

                // siteInfo의 첫번째 edge를 선택
                const edge = siteInfo.edges[0];

                console.warn(`Try MQTT Connect ${edge}`);
                const client = mqtt.connect(mqUrl, {
                    username: user,
                    password: pwd,
                    reconnectPeriod: 10*1000  // 10 sec
                });

                setMqttClient(client);
                client.on('connect', () => {
                    const topic = `edge/${edge}/cache`;
                    client.subscribe(topic, (err) => {
                        if (err) console.error(err);
                    });
                    console.log(`MQ Connected to ${mqUrl} => ${topic}`);
                    setConnected(true);
                });
                client.on('message', (topic, message) => {
                    //console.log(`${topic} -> ${message.toString()}`);
                    const json = JSON.parse(message.toString());
                    json['__time__'] = (new Date()).getTime();
                    // 너무 부하 먹어서 생략
                    //console.log("RECEIVED (text): " + message);
                    //console.log("RECEIVED (text): " + json.href);
                    // Child map 으로 만들어서 넘기자. 검색하기 쉽게
                    const cmap = ObixUtil.cmap(json);
                    // Child map으로 만들면서 root의 주요속성이 날아가네... 그래서 일단은 __time__ 속성을 추가함.
                    cmap.set('__time__', {o: 'int', name: '__time__', val: json.__time__})
                    setObjMap((prev) => new Map(prev).set(json.href, cmap));
                    //setCachedObjs((prev) => new Map(prev).set(json.href, cmap));
                    //console.log("cached");
                    //console.dir(cachedObjs);
                    setObixObj(json);
                    setReceived((prev) => prev+1);
                    // 알람처리
                    setAlarmList((prev) => new Map(prev).set(json.href, cmap.get("alarmList")?.val));
                    setWarningList((prev) => new Map(prev).set(json.href, cmap.get("warningList")?.val));
                });
                client.on('disconnect', (packet) => {
                    console.error(`MQ disconnected ${packet}`);
                    setConnected(false);
                });
                client.on('reconnect', () => {
                    const topic = `edge/${edge}/cache`;
                    // 자동으로 re-subscribe하는 것 확인
                    /*
                    client.subscribe(topic, (err) => {
                        console.error(err);
                    });
                    */
                    console.log(`MQ Reconnected to ${mqUrl} => ${topic}`);
                    setConnected(true);
                });
                client.on('error', (err) => {
                    console.error(err);
                });
            },
            (err) => {
                console.error(err);
            }
        );
        return () => {
            if (mqttClient) mqttClient.end(true);
        }
    }, [appState.mmurl, siteInfo]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (menuMode === 'overlay') {
            hideOverlayMenu();
        }
        if (menuMode === 'static') {
            setDesktopMenuActive(true);
        }
    }, [menuMode]);

    useEffect(() => {
        console.log(`Effect on ${colorMode}`);
        onColorModeChange(colorMode);
    }, [colorMode]); // eslint-disable-line react-hooks/exhaustive-deps

    const onMenuThemeChange = (theme) => {
        setMenuTheme(theme);
    };

    const onTopbarThemeChange = (theme) => {
        setTopbarTheme(theme);
    };

    useEffect(() => {
        const appLogoLink = document.getElementById('app-logo');

        if (topbarTheme === 'white' || topbarTheme === 'yellow' || topbarTheme === 'amber' || topbarTheme === 'orange' || topbarTheme === 'lime') {
            appLogoLink.src = 'assets/layout/images/logo-dark.svg';
        } else {
            appLogoLink.src = 'assets/layout/images/logo-light.svg';
        }
    }, [topbarTheme]);

    const onThemeChange = (theme) => {
        setTheme(theme);
        const themeLink = document.getElementById('theme-css');
        const themeHref = 'assets/theme/' + theme + '/theme-' + colorMode + '.css';
        replaceLink(themeLink, themeHref);
    };

    const onColorModeChange = (mode) => {
        //setColorMode(mode);
        setIsInputBackgroundChanged(true);

        if (isInputBackgroundChanged) {
            if (mode === 'dark') {
                setInputStyle('filled');
            } else {
                setInputStyle('outlined');
            }
        }

        //NOTE: 여기가 실제로 Theme을 정하는 곳임
        if (mode === 'dark') {
            setMenuTheme('dark');
            setTopbarTheme('dark');
        } else {
            setMenuTheme('bluegrey');
            setTopbarTheme('grey');
        }

        //window.localStorage['mmColorMode'] = mode;
        //console.log(`write mmColorMode = ${mode}`);

        const layoutLink = document.getElementById('layout-css');
        const layoutHref = 'assets/layout/css/layout-' + mode + '.css';
        replaceLink(layoutLink, layoutHref);

        const themeLink = document.getElementById('theme-css');
        const urlTokens = themeLink.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = 'theme-' + mode + '.css';
        const newURL = urlTokens.join('/');

        replaceLink(themeLink, newURL, () => {
            setNewThemeLoaded(true);
        });
    };

    const replaceLink = (linkElement, href, callback) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);

            if (callback) {
                callback();
            }
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                const _linkElement = document.getElementById(id);
                _linkElement && _linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        }
    };

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onInlineMenuPositionChange = (mode) => {
        setInlineMenuPosition(mode);
    };

    const onMenuModeChange = (mode) => {
        setMenuMode(mode);
    };


    //TODO: 일단 막음
    const onRTLChange = () => {
        //setRTL((prevState) => !prevState);
    };

    const onMenuClick = (event) => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
        else setMobileMenuActive((prevState) => !prevState);

        event.preventDefault();
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        if (activeTopbarItem === event.item) setActiveTopbarItem(null);
        else {
            setActiveTopbarItem(event.item);
        }

        event.originalEvent.preventDefault();
    };

    const onSearch = (event) => {
        searchClick = true;
        setSearchActive(event);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items && (menuMode === 'overlay' || !isDesktop())) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const onRootMenuItemClick = (event) => {
        setMenuActive((prevState) => !prevState);
    };

    const onRightMenuButtonClick = () => {
        setRightMenuActive((prevState) => !prevState);
    };

    const onMobileTopbarButtonClick = (event) => {
        setMobileTopbarActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onDocumentClick = (event) => {
        if (!searchClick && event.target.localName !== 'input') {
            setSearchActive(false);
        }

        if (!topbarItemClick) {
            setActiveTopbarItem(null);
        }

        if (!menuClick && (menuMode === 'overlay' || !isDesktop())) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }
            hideOverlayMenu();
        }

        if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
            let menuKeys = { ...inlineMenuActive };
            menuKeys[currentInlineMenuKey.current] = false;
            setInlineMenuActive(menuKeys);
        }

        if (!menuClick && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }

        searchClick = false;
        topbarItemClick = false;
        inlineMenuClick = false;
        menuClick = false;
    };

    const hideOverlayMenu = () => {
        setMobileMenuActive(false);
        setDesktopMenuActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onInlineMenuClick = (e, key) => {
        let menuKeys = { ...inlineMenuActive };
        if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
            menuKeys[currentInlineMenuKey.current] = false;
        }

        menuKeys[key] = !menuKeys[key];
        setInlineMenuActive(menuKeys);
        currentInlineMenuKey.current = key;
        inlineMenuClick = true;
    };

    const layoutContainerClassName = classNames('layout-wrapper ', 'layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme, {
        'layout-menu-static': menuMode === 'static',
        'layout-menu-overlay': menuMode === 'overlay',
        'layout-menu-slim': menuMode === 'slim',
        'layout-menu-horizontal': menuMode === 'horizontal',
        'layout-menu-active': desktopMenuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
        'layout-rtl': false // isRTL
    });

    return (
        <div className={layoutContainerClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar
                regions={regions}
                connected={connected} received={received}
                horizontal={isHorizontal()}
                activeTopbarItem={activeTopbarItem}
                onMenuButtonClick={onMenuButtonClick}
                onTopbarItemClick={onTopbarItemClick}
                onRightMenuButtonClick={onRightMenuButtonClick}
                onMobileTopbarButtonClick={onMobileTopbarButtonClick}
                mobileTopbarActive={mobileTopbarActive}
                searchActive={searchActive}
                onSearch={onSearch}
            />

            <div className="menu-wrapper" onClick={onMenuClick}>
                <div className="layout-menu-container">
                    {/*(inlineMenuPosition === 'top' || inlineMenuPosition === 'both') && <AppInlineMenu menuKey="top" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />*/}
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} menuMode={menuMode} active={menuActive} />
                    {/*(inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both') && (
                        <AppInlineMenu menuKey="bottom" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />
                    )*/}
                </div>
            </div>

            <div className="layout-main">
                <div className="layout-content">
                    <Routes>
                        <Route path="/" element={<Dashboard setNotify={setNotify} objmap={objMap} obix={obixObj}/>}/>
                        <Route path="/dashboard" element={<Dashboard setNotify={setNotify} objmap={objMap} site={siteId} siteInfo={siteInfo} obix={obixObj}/>}/>
                        <Route path="/devices" element={<Devices setNotify={setNotify} objmap={objMap} site={siteId} siteInfo={siteInfo}/>}/>
                        <Route path="/monitor" element={<Monitor setNotify={setNotify} objmap={objMap} site={siteId} siteInfo={siteInfo}/>}/>
                        <Route path="/events" element={<Events setNotify={setNotify} site={siteId} siteInfo={siteInfo}/>}/>
                        <Route path="/trends" element={<Trends setNotify={setNotify} site={siteId} siteInfo={siteInfo}/>}/>
                        <Route path="/statistics" element={<Statistics setNotify={setNotify} site={siteId} siteInfo={siteInfo}/>}/>
                        <Route path="/report" element={<Report setNotify={setNotify} site={siteId} siteInfo={siteInfo}/>}/>
                        <Route path="/controls" element={<Controls objmap={objMap}/>}/>
                        <Route path="/studio" element={<ObixStudio setNotify={setNotify} />}/>
                        <Route path="/settings" element={<Settings setNotify={setNotify} />}/>
                        <Route path="/login" element={<Login setNotify={setNotify} />}/>
                        <Route path="/admin-users" element={<AdminUsers setNotify={setNotify} />}/>
                    </Routes>
                </div>
                {/*<AppFooter colorMode={colorMode} />*/}
            </div>

            <AppConfig
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
                rippleEffect={ripple}
                onRippleEffect={onRipple}
                menuMode={menuMode}
                onMenuModeChange={onMenuModeChange}
                inlineMenuPosition={inlineMenuPosition}
                onInlineMenuPositionChange={onInlineMenuPositionChange}
                menuTheme={menuTheme}
                onMenuThemeChange={onMenuThemeChange}
                topbarTheme={topbarTheme}
                onTopbarThemeChange={onTopbarThemeChange}
                theme={theme}
                onThemeChange={onThemeChange}
                isRTL={false /*isRTL*/}
                onRTLChange={onRTLChange}
            />

            <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuButtonClick={onRightMenuButtonClick} />

            {mobileMenuActive && <div className="layout-mask modal-in"></div>}
        </div>
    );
};

export default App;
