import React, { useContext, useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Menu } from 'primereact/menu';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { CSSTransition } from 'react-transition-group';
//import { RTLContext } from './index';
//import { AuthContext } from './index';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AuthStateAtom, SiteIdAtom, SiteInfoAtom } from './atoms';

export function AppLogo(props) {
    const hstyle = {
        align: "left",
        fontStyle: "italic",
        fontSize: "2em",
        margin: 0,
        color: "DarkSeaGreen",
        textShadow: "2px 2px 2px black"
    }

    return <h1 id="app-logo" className="p-m-4" style={hstyle}>Mastermind</h1>
}

export function CommBlink ({connected, count}) {
    let style = { color: "dimgray"}

    if (connected) {
        style = { color: "greenyellow"}
        if ((count % 2) === 0) {
            style = { color: "forestgreen"}
        }
    }
    return (
        <i className="pi pi-circle-on fs-large" style={style} />
    );
}

export function TopClock(props) {
    const siteInfo = useRecoilValue(SiteInfoAtom);
    const [time, setTime] = useState("");
    const [date, setDate] = useState("");

    useEffect(() => {
        let userLocale = navigator.languages && navigator.languages.length
                                ? navigator.languages[0]
                                : navigator.language;
        //userLocale = 'en';
        const interval = setInterval(
            () => {
                const d = new Date();
                setTime(d.toLocaleTimeString(userLocale, {timeZone: siteInfo.zoneId}));
                setDate(d.toLocaleDateString(userLocale, {timeZone: siteInfo.zoneId}));
            },
            1000
        );

        return () => {
            clearInterval(interval);
        }
    }, [siteInfo]);

    return (
        <span>
            <div style={{textAlign: "center"}}>{time}</div>
            <div style={{textAlign: "center"}} className="fs-small">{date}</div>
        </span>
    )
}

const AppTopbar = (props) => {
    // props.connected: commblink를 위한 것
    // props.received: commblink 를 위한 것
    //const authContext = useContext(AuthContext);
    const auth = useRecoilValue(AuthStateAtom);
    const siteInfo = useRecoilValue(SiteInfoAtom);
    const [siteId, setSiteId] = useRecoilState(SiteIdAtom);
    const isRTL = false;
    const navigate = useNavigate();
    const [avatarLetter, setAvatarLetter] = useState("");
    const [avatarStyle, setAvatarStyle] = useState(null);
    const [siteMenuModel, setSiteMenuModel] = useState([]);
    const [siteName, setSiteName] = useState("");
    const [accountMenuModel, setAccountMenuModel] = useState([]);
    const accountMenu = useRef(null);  // 로그인 메뉴
    const siteMenu = useRef(null);  // 로그인 메뉴

    const topbarRef1 = useRef(null);
    const topbarRef2 = useRef(null);
    const topbarRef3 = useRef(null);
    const topbarRef4 = useRef(null);

    // Avatar style 설정
    useEffect(() => {
        if (auth.authenticated && auth.user) {
            setAvatarLetter(auth.user.charAt(0).toUpperCase());
        } else {
            setAvatarLetter("?");
        }
        let theStyle = {};
        if (auth.isAdmin) {
            theStyle = { backgroundColor: '#9c27b0', color: '#ffffff' };
        } else if (auth.isUser) {
            theStyle = {
                backgroundColor: '#2196F3',
                color: '#ffffff'
            };
        } else if (auth.authenticated) {  // viewer
            theStyle = { backgroundColor: '#4caf4f', color: '#ffffff' };
        }
        setAvatarStyle(theStyle);

        const _accountMenuModel = [
            { label: `${auth.user} (${auth.role})`, icon: 'pi pi-check' },
            { label: "Change Password", icon: 'pi pi-key', command: doChangePassword },
            { label: "Log Out", icon: 'pi pi-sign-out', command: doLogOut }
        ];
        setAccountMenuModel(_accountMenuModel);
    }, [auth.user]);

    // Site 선택 메뉴 생성
    useEffect(() => {
        let label;
        if (siteId && props.regions) {
            label = props.regions[siteId];
        }
        if (!label) label = "<None Selected>";
        setSiteName(label);

        const mm = [];
        for (const key of Object.keys(props.regions)) {
            mm.push({
                label: props.regions[key],
                icon: 'pi pi-angle-right',
                command: () => setSiteId(key), // props.setRegion(key)
            })
        }
        setSiteMenuModel(mm);
    }, [props.regions, siteId]);

    const doChangePassword = () => {
        //TODO: 구현해야 함.
    };

    const doLogOut = () => {
        window.localStorage["mm_token"] = "";
        setTimeout(() =>  window.location.href='/login', 500);  // 바로 reload하면 안되어서, timer 줌
    }

    return (
        <div className="layout-topbar shadow-4">
            <div className="layout-topbar-left">
                <button type="button" style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => navigate('/')}>
                    <AppLogo/>
                </button>
                <button type="button" className="layout-menu-button shadow-6 p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-right"></i>
                </button>
                <button type="button" className="layout-topbar-mobile-button p-link">
                    <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopbarButtonClick}></i>
                </button>
            </div>
            <div className={classNames('layout-topbar-right', { 'layout-topbar-mobile-active': props.mobileTopbarActive })}>
                <div className="layout-topbar-actions-left">
                   <Button label={siteName} severity="info" className="px-4 p-button-primary"
                        onClick={(event) => siteMenu.current.toggle(event)}/>
                </div>
                <div className="layout-topbar-actions-right">
                    <ul className="layout-topbar-items">
                        <li className="layout-topbar-item mr-3">
                            <span>{siteInfo.zoneId}</span>
                        </li>
                        <li className="layout-topbar-item mr-3">
                            <TopClock/>
                        </li>
                        <li className="layout-topbar-item">
                            <CommBlink connected={props.connected} count={props.received}/>
                        </li>
                        <li className="layout-topbar-item notifications">
                            <button className="layout-topbar-action rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'notifications' })}>
                                <span className="p-overlay-badge">
                                    <i className="pi pi-bell fs-large"></i>
                                    <span className="p-badge p-badge-warning p-badge-dot"></span>
                                </span>
                            </button>

                            <CSSTransition nodeRef={topbarRef2} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'notifications'} unmountOnExit>
                                <ul ref={topbarRef2} className="layout-topbar-action-panel shadow-6 fadeInDown">
                                    <li className="mb-3">
                                        <span className="px-3 fs-small">
                                            You have <b>4</b> new notifications
                                        </span>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <div className="flex flex-row align-items-center">
                                            <img src="assets/demo/images/avatar/avatar-1.png" alt="" />
                                            <div className={classNames('flex flex-column', { 'ml-3': !isRTL, 'mr-3': isRTL })} style={{ flexGrow: '1' }}>
                                                <div className="flex align-items-center justify-content-between mb-1">
                                                    <span className="fs-small font-bold">Jerome Bell</span>
                                                    <small>42 mins ago</small>
                                                </div>
                                                <span className="fs-small">How to write content about your photographs?</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <div className="flex flex-row align-items-center">
                                            <img src="assets/demo/images/avatar/avatar-2.png" alt="" />
                                            <div className={classNames('flex flex-column', { 'ml-3': !isRTL, 'mr-3': isRTL })} style={{ flexGrow: '1' }}>
                                                <div className="flex align-items-center justify-content-between mb-1">
                                                    <span className="fs-small font-bold">Cameron Williamson</span>
                                                    <small>48 mins ago</small>
                                                </div>
                                                <span className="fs-small">Start a blog to reach your creative peak.</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <div className="flex flex-row align-items-center">
                                            <img src="assets/demo/images/avatar/avatar-3.png" alt="" />
                                            <div className={classNames('flex flex-column', { 'ml-3': !isRTL, 'mr-3': isRTL })} style={{ flexGrow: '1' }}>
                                                <div className="flex align-items-center justify-content-between mb-1">
                                                    <span className="fs-small font-bold">Anna Miles</span>
                                                    <small>1 day ago</small>
                                                </div>
                                                <span className="fs-small">Caring is the new marketing</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <div className="flex flex-row align-items-center">
                                            <img src="assets/demo/images/avatar/avatar-4.png" alt="" />
                                            <div className={classNames('flex flex-column', { 'ml-3': !isRTL, 'mr-3': isRTL })} style={{ flexGrow: '1' }}>
                                                <div className="flex align-items-center justify-content-between mb-1">
                                                    <span className="fs-small font-bold">Arlene Mccoy</span>
                                                    <small>4 day ago</small>
                                                </div>
                                                <span className="fs-small">Starting your traveling blog with Vasco.</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </CSSTransition>
                        </li>
                        <li className="layout-topbar-item">
                            <Avatar label={avatarLetter} className="mr-4" size="large" shape="circle" style={avatarStyle}
                                onClick={(event) => accountMenu.current.toggle(event)}/>
                        </li>
                    </ul>
                </div>
            </div>
            <Menu model={accountMenuModel} popup ref={accountMenu} id="myMenu"/>
            <Menu model={siteMenuModel} popup ref={siteMenu} id="siteMenu" style={{width: "300px"}}/>
        </div>
    );
};

export default AppTopbar;
