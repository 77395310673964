import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog'; // To use <ConfirmDialog> tag
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { Toast } from 'primereact/toast';
import { AppStateAtom, SiteIdAtom, SiteInfoAtom } from '../atoms';
import { useRecoilValue } from 'recoil';
import { ObixUtil } from '../service/ObixUtil';
import { EssServices, DeviceCard } from './Devices';

export function Controls(props) {
    // props.objmap : live objmap

    return (
        <div className="grid">
            <div className="col">
                <TaskList/>
            </div>
            <div className="col-fixed" style={{width: "400px"}}>
                <DeviceList objmap={props.objmap}/>
            </div>
        </div>
    );
}

function TaskList(props) {
    const appState = useRecoilValue(AppStateAtom);
    const siteInfo = useRecoilValue(SiteInfoAtom);
    const [taskNames, setTaskNames] = useState([]);
    const toast = useRef(null);

    useEffect(() => {
        if (!appState.mmurl) return;
        if (!siteInfo.edges) return;
        let tasksUrl = `${appState.mmurl}/obix/edges/${siteInfo.edges[0]}/obix/services/cron/tasks`;
        ObixUtil.readObix(tasksUrl, 
            resp => {
                const tn = [];
                for (const item of resp.data.c) {
                    if (item.o === "ref")
                        tn.push(item.name);
                }
                setTaskNames(tn);
            },
            err => {
                console.error(err);
            }
        );
    }, [appState.mmurl, siteInfo]);

    return (
        <Card title={<h5 className="m-0"><i className="pi pi-forward"></i> Control Actions</h5>} className="p-2">
            {taskNames.map(item => <TaskItem key={item} task={item} toastRef={toast}/>)}
            <ConfirmDialog />
            <Toast ref={toast}></Toast>
        </Card>
    );
}

function TaskItem(props) {
    // props.task
    // props.toastRef
    const appState = useRecoilValue(AppStateAtom);
    const siteInfo = useRecoilValue(SiteInfoAtom);
    const [task, setTask] = useState({});
    const [widget, setWidget] = useState(null);

    const runScript = () => {
        console.log("Accepted");
        props.toastRef.current.show({ severity: 'info', summary: 'Run Script', detail: `Running script ${props.task} ...` });
        let tasksUrl = `${appState.mmurl}/obix/edges/${siteInfo.edges[0]}/obix/services/cron/tasks/${props.task}/wetRun`;
        ObixUtil.invokeObix(tasksUrl, {},
            resp => {
                console.dir(resp.data);
                let rt = resp.data.c[0].display;
                props.toastRef.current.show({ severity: 'success', summary: 'Run Script', detail: `Script ${props.task} ended with [${rt}]`, life: 10000 });
            },
            err => {
                console.error(err);
                props.toastRef.current.show({ severity: 'error', summary: `Failed to run script ${props.task}`, detail: err, life: 30000 });
            }
        );                
    }

    const onRunScript = () => {        
        confirmDialog({
            message: "Are you sure want to proceed?",
            header: "Confirmation",
            icon: 'pi pi-exclamation-triangle',
            accept: runScript,
            reject: () => { console.log("Rejected") }
        });
    }
    useEffect(() => {
        if (!appState.mmurl) return;
        if (!siteInfo.edges) return;
        let tasksUrl = `${appState.mmurl}/obix/edges/${siteInfo.edges[0]}/obix/services/cron/tasks/${props.task}`;
        ObixUtil.readObix(tasksUrl, 
            resp => {
                const t = ObixUtil.toObj(resp.data);
                setTask(t);  // Object 형태로 담김. 

                if (t?.type === "groovy") {
                    setWidget(
                        <div>
                            <hr className="m-2"/>
                            <h6 className="mt-0 mb-2">{t.name}</h6>
                            <div className="flex">
                                <div>
                                    <div className="mb-1 pl-2"> <i className="pi pi-comment"></i> {t.description}</div>
                                    <div className="mb-1 pl-2">
                                    { t.active === true ? 
                                        <span><i className="pi pi-clock"/> Scheduled {t.schedule} ({t.pattern})</span> :
                                        <span className="text-500"><i className="pi pi-power-off"/> Not scheduled</span>
                                    }
                                    </div>
                                </div>
                                <div className="ml-auto pr-3">
                                    <Button label="Run Script" onClick={onRunScript} className="p-button-success p-button-raised p-button-rounded" />
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    setWidget(null);
                }
            },
            err => {
                console.error(err);
            }
        );
    }, [appState.mmurl, siteInfo, props.task]);

    return (
        <div>
            {widget}
        </div>
    );
}

function DeviceList(props) {
    // props.objmap : live objmap
    const siteInfo = useRecoilValue(SiteInfoAtom);
    const [edge, setEdge] = useState(null);
    const [devices, setDevices] = useState([]);
    useEffect(() => {
        if (!siteInfo?.devices) return;
        if (!siteInfo?.edges) return;
        const deviceList = siteInfo?.devices[2];  //TODO: 임시조치임 
        setEdge(siteInfo?.edges[0]);  //TODO: 임시조치임 

        const dw = [];
        if (deviceList) {
            for (const u of deviceList) {
                dw.push(<DeviceCard key={u} uri={u} objmap={props.objmap} edge={edge} embedded={true} />);
            }
        }
        setDevices(dw);
    }, [siteInfo, props.objmap]);
    return (
        <div>
            <EssServices edge={edge}/>
            { devices }
        </div>
    );
}