import { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { Card } from 'primereact/card';
import { Knob } from 'primereact/knob';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
//import { AppContext } from '../index';
import { AppStateAtom, ColorModeAtom, SiteIdAtom, SiteInfoAtom } from '../atoms';
import { useRecoilValue } from 'recoil';
import { SingleLineSvgObd } from '../sites/Oebyungdo';
import { SingleLineSvgBvr } from '../sites/Bvr';
import { SingleLineSvgVietnam } from '../sites/Vietnam';
import { ObixUtil } from '../service/ObixUtil';
//import { ColorModeContext } from '../App';
import ReactECharts from 'echarts-for-react';
import { useNavigate } from 'react-router-dom';

export function Dashboard(props) {
    // props.objmap
    // props.obix
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const siteId = useRecoilValue(SiteIdAtom);
    const siteInfo = useRecoilValue(SiteInfoAtom);
    const [cards, setCards] = useState(null);
    useEffect(() => {
        const dashboard = siteInfo?.dashboard;
        const ca = [];
        ca.push(
            <div key="info" className="col-12 md:col-6 lg:col-4 xl:col">
                <InfoCard site={siteId} siteInfo={siteInfo} />
            </div>
        );

        if (siteInfo?.dashboard) {
            for (const k of Object.keys(siteInfo.dashboard)) {
                if (k === "meter") {
                    ca.push(
                        <div key={k} className="col-12 md:col-6 lg:col-4 xl:col">
                            <MeterCard objmap={props.objmap} site={siteId} siteInfo={siteInfo} />
                        </div>
                    );
                } else if (k === "pv") {
                    ca.push(
                        <div key={k} className="col-12 md:col-6 lg:col-4 xl:col">
                            <PvCard objmap={props.objmap} site={siteId} siteInfo={siteInfo} />
                        </div>
                    );
                } else if (k === "pcs") {
                    ca.push(
                        <div key={k} className="col-12 md:col-6 lg:col-4 xl:col">
                            <PcsCard objmap={props.objmap} site={siteId} siteInfo={siteInfo} />
                        </div>
                    );
                } else if (k === "genset") {
                    ca.push(
                        <div key={k} className="col-12 md:col-6 lg:col-4 xl:col">
                            <GensetCard objmap={props.objmap} site={siteId} siteInfo={siteInfo} />
                        </div>
                    );
                } else if (k === "bat") {
                    ca.push(
                        <div key={k} className="col-12 md:col-6 lg:col-4 xl:col">
                            <BatteryCard objmap={props.objmap} site={siteId} siteInfo={siteInfo} />
                        </div>
                    );
                } else {
                    //console.warn("Not supported card type: " + k);
                }
                if (ca.length > 4) break;
            }
        }
        setCards(ca);
    }, [siteInfo, props.objmap]);
    return (
        <div>
            <div className="grid">
                {cards}
            </div>
            <div className="grid mb-2">
                <div className="col-12 xl:col-6">
                    <SingleLineCard objmap={props.objmap} site={siteId} siteInfo={siteInfo} />
                </div>

                <div className="col-12 xl:col-6">
                    <Chart24Card site={siteId} siteInfo={siteInfo} />
                </div>
            </div>

            <div className="grid">
                <div className="col">
                    <EventsCard obix={props.obix} site={siteId} siteInfo={siteInfo} />
                </div>
            </div>
        </div>
    );
}

export function InfoCard(props) {
    // props.site
    // props.siteInfo
    const [devices, setDevices] = useState(0);
    useEffect(() => {
        if (!props.siteInfo?.devices) return;
        let c = 0;
        for (const a of props.siteInfo.devices) {
            c = c + a.length;
        }
        setDevices(c);
    }, [props.siteInfo]);
    const title = <span>
        <h5 className="m-0"><i className="pi pi-info-circle"></i> Site Info</h5>
    </span>;
    return (
        <Card title={title} style={{minHeight:"180px"}}>
            <div className="text-lg mb-3 font-bold">{props.siteInfo.name_ko}</div>
            <div className="mb-2">Loc: {props.siteInfo.location}</div>
            <div className="mb-2">Started: {props.siteInfo.since}</div>
            {/*<div className="mb-2">감시장치수: {devices}</div>*/}
        </Card>
    );
}

export function MeterCard(props) {
    // props.objmap
    // props.site
    // props.siteInfo
    const [power, setPower] = useState(0);
    const [max, setMax] = useState(100);
    const [min, setMin] = useState(0);
    const [edge, setEdge] = useState(null);
    const [uri, setUri] = useState(null);
    const [today, setToday] = useState(0);
    const [month, setMonth] = useState(0);
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);

    useEffect(() => {
        if (!props.siteInfo) return;
        const meterUri = props.siteInfo?.dashboard?.meter?.uri;  // 이제 배열임         
        if (!meterUri) return;
        let pow = 0;
        for (const u of meterUri) {
            const meterObj = props.objmap.get(u);
            pow = pow + Number((ObixUtil.findAny(meterObj, ["power"]))?.val || 0);
        }
        setPower(Math.round(pow));
        setMax(props.siteInfo?.dashboard?.meter?.max || 100);
        setMin(props.siteInfo?.dashboard?.meter?.min || 0);
        setEdge(props.siteInfo?.dashboard?.meter?.edge);
        setUri(props.siteInfo?.dashboard?.meter?.uri);
    }, [props.siteInfo, props.objmap]);

    useEffect(() => {
        const func = () => {
            getTodayMonthEnergy(edge, uri, appState.mmurl,
                (resp) => {
                    setToday(Math.round(resp.data.day[0].energy) || 0);
                    setMonth(Math.round(resp.data.month[0].energy) || 0);
                },
                (err) => {
                    console.error(err);
                }
            );
        }
        const timer = setInterval(() => {
            func();
        }, 60 * 1000);
        func();  // 60초 뒤에 실행이 되어서, 처음에는 한번 불러준다. 
        return () => clearInterval(timer);
    }, [appState.mmurl, edge, uri]);

    const title = <span>
        <h5 className="m-0"><i className="pi pi-box"></i> Meter</h5>
    </span>;

    return (
        <Card title={title} className="mb-2">
            <div className="grid">
                <div className="col">
                    <Knob value={power} min={min} max={max} style={{ textAlign: "center" }} valueTemplate={'{value}kW'} valueColor="var(--blue-600)" />
                </div>
                <div className="col" style={{ textAlign: "center" }}>
                    <div className="text-base">Today</div>
                    <div className="text-lg font-bold mb-3">{today.toLocaleString()} kWh</div>
                    <div className="text-base">This Month</div>
                    <div className="text-lg font-bold">{month.toLocaleString()} kWh</div>
                </div>
            </div>
        </Card>
    );
}

export function PvCard(props) {
    // props.objmap
    // props.site
    // props.siteInfo
    const [power, setPower] = useState(0);
    const [max, setMax] = useState(100);
    const [min, setMin] = useState(0);
    const [edge, setEdge] = useState(null);
    const [uri, setUri] = useState(null);
    const [today, setToday] = useState(0);
    const [month, setMonth] = useState(0);
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);

    useEffect(() => {
        if (!props.siteInfo) return;
        const ua = props.siteInfo?.dashboard?.pv?.uri;  // 이제 배열임         
        if (!ua) return;
        let pow = 0;
        for (const u of ua) {
            const o = props.objmap.get(u);
            pow = pow + Number((ObixUtil.findAny(o, ["power", "dcPower"]))?.val || 0);
        }
        setPower(Math.round(pow));
        setMax(props.siteInfo?.dashboard?.pv?.max || 100);
        setMin(props.siteInfo?.dashboard?.pv?.min || 0);
        setEdge(props.siteInfo?.dashboard?.pv?.edge);
        setUri(props.siteInfo?.dashboard?.pv?.uri);
    }, [props.siteInfo, props.objmap]);

    useEffect(() => {
        const func = () => {
            getTodayMonthEnergy(edge, uri, appState.mmurl,
                (resp) => {
                    setToday(Math.round(resp.data.day[0].generated) || 0);
                    setMonth(Math.round(resp.data.month[0].generated) || 0);
                },
                (err) => {
                    console.error(err);
                }
            );
        }
        const timer = setInterval(() => {
            func();
        }, 60 * 1000);
        func();  // 60초 뒤에 실행이 되어서, 처음에는 한번 불러준다. 
        return () => clearInterval(timer);
    }, [appState.mmurl, edge, uri]);

    const title = <span>
        <h5 className="m-0"><i className="pi pi-sun"></i> Solar</h5>
    </span>;

    return (
        <Card title={title}>
            <div className="grid">
                <div className="col">
                    <Knob value={power} min={min} max={max} style={{ textAlign: "center" }} valueTemplate={'{value}kW'} valueColor="var(--green-500)" />
                </div>
                <div className="col" style={{ textAlign: "center" }}>
                    <div className="text-base">Today</div>
                    <div className="text-lg font-bold mb-3">{today.toLocaleString()} kWh</div>
                    <div className="text-base">This Month</div>
                    <div className="text-lg font-bold">{month.toLocaleString()} kWh</div>
                </div>
            </div>
        </Card>
    );
}

export function PcsCard(props) {
    // props.objmap
    // props.site
    // props.siteInfo
    const [power, setPower] = useState(0);
    const [max, setMax] = useState(100);
    const [min, setMin] = useState(0);
    const [edge, setEdge] = useState(null);
    const [uri, setUri] = useState(null);
    const [todayCharged, setTodayCharged] = useState(0);
    const [todayDischarged, setTodayDischarged] = useState(0);
    const [monthCharged, setMonthCharged] = useState(0);
    const [monthDischarged, setMonthDischarged] = useState(0);
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);

    useEffect(() => {
        if (!props.siteInfo) return;
        const ua = props.siteInfo?.dashboard?.pcs?.uri;  // 이제 배열임         
        if (!ua) return;
        let pow = 0;
        for (const u of ua) {
            const o = props.objmap.get(u);
            pow = pow + Number((ObixUtil.findAny(o, ["power", "dcPower"]))?.val || 0);
        }
        setPower(Math.round(pow));
        setMax(props.siteInfo?.dashboard?.pcs?.max || 100);
        setMin(props.siteInfo?.dashboard?.pcs?.min || 0);
        setEdge(props.siteInfo?.dashboard?.pcs?.edge);
        setUri(props.siteInfo?.dashboard?.pcs?.uri);
    }, [props.siteInfo, props.objmap]);

    useEffect(() => {
        const func = () => {
            getTodayMonthEnergy(edge, uri, appState.mmurl,
                (resp) => {
                    setTodayCharged(Math.round(resp.data.day[0].charged) || 0);
                    setTodayDischarged(Math.round(resp.data.day[0].discharged) || 0);
                    setMonthCharged(Math.round(resp.data.month[0].charged) || 0);
                    setMonthDischarged(Math.round(resp.data.month[0].discharged) || 0);
                },
                (err) => {
                    console.error(err);
                }
            );
        }
        const timer = setInterval(() => {
            func();
        }, 60 * 1000);
        func();  // 60초 뒤에 실행이 되어서, 처음에는 한번 불러준다. 
        return () => clearInterval(timer);
    }, [appState.mmurl, edge, uri]);

    const title = <span>
        <h5 className="m-0"><i className="pi pi-calendar-plus"></i> PCS</h5>
    </span>;

    return (
        <Card title={title}>
            <div className="grid">
                <div className="col">
                    <Knob value={power} min={min} max={max} style={{ textAlign: "center" }} valueTemplate={'{value}kW'} valueColor="var(--purple-500)" />
                </div>
                <div className="col" style={{ textAlign: "center" }}>
                    <div className="text-sm ">Today</div>
                    <div className="text-base font-bold">C {todayCharged.toLocaleString()} kWh</div>
                    <div className="text-base font-bold">D {todayDischarged.toLocaleString()} kWh</div>
                    <div className="text-sm mt-1">This Month</div>
                    <div className="text-base font-bold">C {monthCharged.toLocaleString()} kWh</div>
                    <div className="text-base font-bold">D {monthDischarged.toLocaleString()} kWh</div>
                </div>
            </div>
        </Card>
    );
}

export function GensetCard(props) {
    // props.objmap
    // props.site
    // props.siteInfo
    const [power, setPower] = useState(0);
    const [max, setMax] = useState(100);
    const [min, setMin] = useState(0);
    const [edge, setEdge] = useState(null);
    const [uri, setUri] = useState(null);
    const [today, setToday] = useState(0);
    const [month, setMonth] = useState(0);
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);

    useEffect(() => {
        if (!props.siteInfo) return;
        const ua = props.siteInfo?.dashboard?.genset?.uri;  // 이제 배열임         
        if (!ua) return;
        let pow = 0;
        for (const u of ua) {
            const o = props.objmap.get(u);
            pow = pow + Number((ObixUtil.findAny(o, ["power", "dcPower"]))?.val || 0);
        }
        setPower(Math.round(pow));
        setMax(props.siteInfo?.dashboard?.genset?.max || 100);
        setMin(props.siteInfo?.dashboard?.genset?.min || 0);
        setEdge(props.siteInfo?.dashboard?.genset?.edge);
        setUri(props.siteInfo?.dashboard?.genset?.uri);
    }, [props.siteInfo, props.objmap]);

    useEffect(() => {
        const func = () => {
            getTodayMonthEnergy(edge, uri, appState.mmurl,
                (resp) => {
                    setToday(Math.round(resp.data.day[0].generated) || 0);
                    setMonth(Math.round(resp.data.month[0].generated) || 0);
                },
                (err) => {
                    console.error(err);
                }
            );
        }
        const timer = setInterval(() => {
            func();
        }, 60 * 1000);
        func();  // 60초 뒤에 실행이 되어서, 처음에는 한번 불러준다. 
        return () => clearInterval(timer);
    }, [appState.mmurl, edge, uri]);

    const title = <span>
        <h5 className="m-0"><i className="pi pi-bolt"></i> Genset</h5>
    </span>;

    return (
        <Card title={title}>
            <div className="grid">
                <div className="col">
                    <Knob value={power} min={min} max={max} style={{ textAlign: "center" }} valueTemplate={'{value}kW'} valueColor="Crimson" />
                </div>
                <div className="col" style={{ textAlign: "center" }}>
                    <div className="text-base">Today</div>
                    <div className="text-lg font-bold mb-3">{today.toLocaleString()} kWh</div>
                    <div className="text-base">This Month</div>
                    <div className="text-lg font-bold">{month.toLocaleString()} kWh</div>
                </div>
            </div>
        </Card>
    );
}

export function BatteryCard(props) {
    // props.objmap
    // props.site
    // props.siteInfo
    const [power, setPower] = useState(0);
    const [max, setMax] = useState(100);
    const [min, setMin] = useState(0);
    const [edge, setEdge] = useState(null);
    const [uri, setUri] = useState(null);
    const [today, setToday] = useState(0);
    const [month, setMonth] = useState(0);
    const [socRange, setSocRange] = useState("-");
    const [voltRange, setVoltRange] = useState("-");
    //const appContext = useContext(AppContext);

    useEffect(() => {
        if (!props.siteInfo) return;
        const ua = props.siteInfo?.dashboard?.bat?.uri;  // 이제 배열임         
        if (!ua) return;
        let pow = 0;
        let minSoc = 100;
        let maxSoc = 0;
        let minVolt = 99999;
        let maxVolt = 0;
        for (const u of ua) {
            const o = props.objmap.get(u);
            pow = pow + Number((ObixUtil.findAny(o, ["power", "dcPower"]))?.val || 0);
            const s = Number((ObixUtil.findAny(o, ["SOC"]))?.val || 0);
            if (s > maxSoc) maxSoc = s;
            if (s < minSoc) minSoc = s;
            const v = Number((ObixUtil.findAny(o, ["dcVoltage", "rackVolt", "rackVoltage"]))?.val || 0);
            if (v > maxVolt) maxVolt = v;
            if (v < minVolt) minVolt = v;
        }
        setPower(Math.round(pow));
        setMax(props.siteInfo?.dashboard?.bat?.max || 100);
        setMin(props.siteInfo?.dashboard?.bat?.min || -100);

        if (ua.length > 1) {
            setSocRange(`${minSoc}~${maxSoc}%`);
            setVoltRange(`${minVolt}~${maxVolt}V`);
        } else {
            setSocRange(`${minSoc}%`);
            setVoltRange(`${minVolt}V`);
        }
    }, [props.siteInfo, props.objmap]);
    const title = <span>
        <h5 className="m-0"><i className="pi pi-bolt"></i> Battery</h5>
    </span>;

    return (
        <Card title={title}>
            <div className="grid">
                <div className="col">
                    <Knob value={power} min={min} max={max} style={{ textAlign: "center" }} valueTemplate={'{value}kW'} valueColor="var(--orange-500)" />                    
                </div>
                <div className="col" style={{ textAlign: "center" }}>
                    <div className="text-base">SOC</div>
                    <div className="text-lg font-bold mb-3">{socRange}</div>
                    <div className="text-base">Voltage</div>
                    <div className="text-lg font-bold">{voltRange}</div>
                </div>
            </div>
        </Card>
    );
}

export function SingleLineCard(props) {
    // props.objmap
    // props.site
    // props.siteInfo
    const [svg, setSvg] = useState(null);
    useEffect(() => {
        //console.log(`props.site=${props.site}`);
        if (props?.site === "oebyungdo") {
            setSvg(<SingleLineSvgObd objmap={props.objmap} site={props.site} siteInfo={props.siteInfo} />);
        } else if (props?.site === "bvr") {
            setSvg(<SingleLineSvgBvr objmap={props.objmap} site={props.site} siteInfo={props.siteInfo} />);        
        } else if (props?.site === "vietnam") {
            setSvg(<SingleLineSvgVietnam objmap={props.objmap} site={props.site} siteInfo={props.siteInfo} />);                
        } else {
            setSvg(<SingleLineSvgDefault />);
        }
    }, [props.site, props.siteInfo, props.objmap]);
    const title = <span>
        <h5 className="m-0"><i className="pi pi-forward"></i> Single Line</h5>
    </span>;

    return (
        <Card title={title}>
            <div style={{ height: 350, textAlign: "center" }}>
                {svg}
            </div>
        </Card>
    );
}

export function SingleLineSvgDefault(props) {
    const ix0 = 165;
    const ix1 = 650;
    const ix2 = 840;
    const iy0 = 50;
    const iy1 = 220;
    const iy2 = 385;
    const iw = 75;
    const ih = 75;
    const jx = 450;
    return (
        <svg width="100%" height="100%" viewBox="0 0 1024 526">
            <g>
                <text>Not implemented</text>
            </g>
        </svg>
    );
}

export function Chart24Card(props) {
    // props.site
    // props.siteInfo
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const [meterData, setMeterData] = useState([]);
    const [pvData, setPvData] = useState([]);
    const [pcsData, setPcsData] = useState([]);
    const [gensetData, setGensetData] = useState([]);
    const [socData1, setSocData1] = useState([]);
    const [socData2, setSocData2] = useState([]);
    const [socData3, setSocData3] = useState([]);
    const [socData4, setSocData4] = useState([]);

    useEffect(() => {
        if (!props.site) return;
        const func = () => {
            let invokeUrl = appState.mmurl + '/obix/histories/history/queryCombined';
            let body = {
                o: 'obix',
                c: [
                    { o: 'str', name: 'site', val: props.site }
                ]
            }

            ObixUtil.invokeObix(invokeUrl, body,
                (resp) => {
                    console.dir(resp.data);
                    if (resp.data?.meter) {
                        const d = [];
                        for (const item of resp.data.meter) {
                            d.push([item.ttime, Math.round((item.energy - (item?.energyExport || 0)) * 4)]);  //kW 단위로 환산 
                        }
                        setMeterData(d);
                    } else {
                        setMeterData([]);
                    }
                    if (resp.data?.pv) {
                        const d = [];
                        for (const item of resp.data.pv) {
                            d.push([item.ttime, Math.round(-item.generated * 4)]);  //kW 단위로 환산 
                        }
                        setPvData(d);
                    } else {
                        setPvData([]);
                    }
                    if (resp.data?.pcs) {
                        //console.log("PCSPCS");
                        //console.dir(resp.data.pcs);
                        const d = [];
                        for (const item of resp.data.pcs) {
                            d.push([item.ttime, Math.round((item.charged - item.discharged) * 4)]);  //kW 단위로 환산 
                        }
                        setPcsData(d);
                    } else {
                        setPcsData([]);
                    }
                    if (resp.data?.genset) {
                        const d = [];
                        for (const item of resp.data.genset) {
                            d.push([item.ttime, Math.round((-item.generated) * 4)]);  //kW 단위로 환산 
                        }
                        setGensetData(d);
                    } else {
                        setGensetData([]);
                    }
                    if (resp.data?.bat1) {
                        const d = [];
                        for (const item of resp.data.bat1) {
                            if ('SOC' in item)
                                d.push([item.ttime, Math.round(item.SOC * 10.0) / 10.0]);
                        }
                        setSocData1(d);
                    } else {
                        setSocData1([]);
                    }
                    if (resp.data?.bat2) {
                        const d = [];
                        for (const item of resp.data.bat2) {
                            if ('SOC' in item)
                                d.push([item.ttime, Math.round(item.SOC * 10.0) / 10.0]);
                        }
                        setSocData2(d);
                    } else {
                        setSocData2([]);
                    }
                    if (resp.data?.bat3) {
                        const d = [];
                        for (const item of resp.data.bat3) {
                            if ('SOC' in item)
                                d.push([item.ttime, Math.round(item.SOC * 10.0) / 10.0]);
                        }
                        setSocData3(d);
                    } else {
                        setSocData3([]);
                    }
                    if (resp.data?.bat4) {
                        const d = [];
                        for (const item of resp.data.bat4) {
                            if ('SOC' in item)
                                d.push([item.ttime, Math.round(item.SOC * 10.0) / 10.0]);
                        }
                        setSocData4(d);
                    } else {
                        setSocData4([]);
                    }

                },
                (err) => {
                    console.error(err);
                }
            );
        }
        const timer = setInterval(() => {
            func();
        }, 60 * 1000);
        func();  // 60초 뒤에 실행이 되어서, 처음에는 한번 불러준다. 
        return () => clearInterval(timer);
    }, [props.site, appState.mmurl]);

    const title = <span>
        <h5 className="m-0"><i className="pi pi-chart-line"></i> Combined Chart for 24Hours</h5>
    </span>;
    return (
        <Card title={title}>
            <div style={{ height: 350, textAlign: "center" }}>
                <CombinedChart meterData={meterData} pvData={pvData} pcsData={pcsData} gensetData={gensetData} 
                    socData1={socData1} socData2={socData2} socData3={socData3} socData4={socData4} cstyle={{ height: '370px', width: '100%' }} />
            </div>
        </Card>
    );
}

export function EventsCard(props) {
    // props.obix
    // props.site
    // props.siteInfo
    const [events, setEvents] = useState([]);
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const navigate = useNavigate();

    function loadEvents() {
        //console.log(`load entered: ${appContext.sgurl}`);
        if (!appState.mmurl) return;
        if (!props.site) return;
        const limit = 25;
        let invokeUrl = appState.mmurl + "/obix/histories/events/query";
        let body = {
            o: 'obix',
            c: [
                { o: "int", name: "limit", val: limit },
                { o: "int", name: "offset", val: 0 },
                { o: "bool", name: "reverse", val: true },
                { o: "str", name: "site", val: props.site }
            ]
        }
        ObixUtil.invokeObix(invokeUrl, body,
            (resp) => {
                // -> data(list) -> c
                // 결과 보여주는 창 띄우기 
                //onsole.log("Events loaded");
                let childs = ObixUtil.find(resp.data, "data").c;
                const total = ObixUtil.find(resp.data, "total")?.val;
                let events = [];
                if (childs) {
                    for (const c of childs) {
                        let item = {
                            eid: ObixUtil.find(c, "eid")?.val,
                            geid: ObixUtil.find(c, "geid")?.val,
                            edge: ObixUtil.find(c, "edge")?.val,
                            ttime: ObixUtil.find(c, "when")?.val,
                            source: ObixUtil.find(c, "src")?.val,
                            category: ObixUtil.find(c, "cat")?.val,
                            detail: ObixUtil.find(c, "msg")?.val,
                        }
                        events.push(item);
                    }
                }
                //console.dir(events);
                setEvents(events);
            },
            (errmsg) => {
                console.error(errmsg);
            }
        );
    }

    useEffect(() => {
        //console.log(`obix=${props.obix.href}`);
        // 이벤트가 들어오면 그냥, 새로 리프레시 하자. 
        if (props.obix?.href === "/obix/feeds/event") {
            loadEvents();
        }
    }, [props.obix]);

    useEffect(() => {
        loadEvents();
    }, [props.site]);

    const title = <span>
        <h5 className="m-0"><i className="pi pi-volume-up"></i> Recent Events <Tag value="More" severity="info" onClick={(e) => navigate('/events')} style={{ cursor: "pointer" }} /></h5>
    </span>;

    const categoryTemplate = (rowData) => {
        let catStyle = {}
        if (["ERROR", "FAULT"].includes(rowData.category)) {
            catStyle = { color: "red", fontWeight: "bold" };
        } else if (["WARNING", "WARN"].includes(rowData.category)) {
            catStyle = { color: "orange", fontWeight: "bold" };
        }
        return <span style={catStyle}>{rowData.category}</span>
    }

    return (
        <Card title={title}>
            <div>
                <DataTable value={events} resizableColumns columnResizeMode="fit" showGridlines autoLayout={true} size="small" responsiveLayout="scroll">
                    <Column field="eid" header="ID" style={{ width: "80px" }} ></Column>
                    <Column field="geid" header="ID(GW)" style={{ width: "80px" }} ></Column>
                    <Column field="edge" header="Edge" style={{ width: "80px" }} ></Column>
                    <Column field="ttime" header="Time" style={{ width: "220px" }} ></Column>
                    <Column field="category" header="Category" body={categoryTemplate} style={{ width: "100px" }} ></Column>
                    <Column field="source" header="Source" style={{ width: "300px" }} ></Column>
                    <Column field="detail" header="Details"></Column>
                </DataTable>
            </div>
        </Card>
    );
}

export function CombinedChart(props) {
    // props.meterData:  
    // props.pvData:
    // props.cstyle
    //const colorModeContext = useContext(ColorModeContext);
    const colorMode = useRecoilValue(ColorModeAtom);
    const chartMemo = useMemo(() => {
        const series = [];
        const legends = [];

        if (props.meterData && props.meterData.length > 0) {
            series.push({
                name: "Meter",
                type: 'line',
                showSymbol: false,
                data: props.meterData,
                yAxisIndex: 0,
                itemStyle: {
                    color: '#1c80cf',
                },
                areaStyle: {
                    color: '#1c80cf',
                    opacity: 0.3
                },
                zlevel: 10
            });
            legends.push("Meter");
        }

        if (props.pvData && props.pvData.length > 0) {
            series.push({
                name: "PV",
                type: 'line',
                showSymbol: false,
                data: props.pvData,
                yAxisIndex: 0,
                itemStyle: {
                    color: '#4caf50',
                },
                zlevel: 5
            });
            legends.push("PV");
        }
        
        if (props.pcsData && props.pcsData.length > 0) {
            series.push({
                name: "PCS",
                type: 'bar',
                showSymbol: false,
                data: props.pcsData,
                yAxisIndex: 0,
                zlevel: 1,
                itemStyle: {
                    color: '#c279ce',
                },
                zlevel: 1
            });        
            legends.push("PCS");
        }
        
        if (props.gensetData && props.gensetData.length > 0) {
            series.push({
                name: "Genset",
                type: 'line',
                showSymbol: false,
                data: props.gensetData,
                yAxisIndex: 0,
                itemStyle: {
                    color: '#ff4032',
                },
                zlevel: 1
            });
            legends.push("Genset");
        }

        series.push({
            name: "SOC",
            type: 'line',
            showSymbol: false,
            data: props.socData1,
            yAxisIndex: 1, // #fbc02d
            itemStyle: {
                color: '#757575',
            },
            lineStyle: {
                normal: {
                    type: 'dashed'
                }
            },
            zlevel: 20
        });
        legends.push("SOC");

        if (props.socData2.length > 0) {
            series.push({
                name: "SOC2",
                type: 'line',
                showSymbol: false,
                data: props.socData2,
                yAxisIndex: 1, // #fbc02d
                itemStyle: {
                    color: '#757575',
                },
                lineStyle: {
                    normal: {
                        type: 'dashed'
                    }
                },
                zlevel: 20
            });
            legends.push("SOC2");
        }

        if (props.socData3.length > 0) {
            series.push({
                name: "SOC3",
                type: 'line',
                showSymbol: false,
                data: props.socData3,
                yAxisIndex: 1, // #fbc02d
                itemStyle: {
                    color: '#757575',
                },
                lineStyle: {
                    normal: {
                        type: 'dashed'
                    }
                },
                zlevel: 20
            });
            legends.push("SOC3");
        }

        if (props.socData4.length > 0) {
            series.push({
                name: "SOC4",
                type: 'line',
                showSymbol: false,
                data: props.socData4,
                yAxisIndex: 1, // #fbc02d
                itemStyle: {
                    color: '#757575',
                },
                lineStyle: {
                    normal: {
                        type: 'dashed'
                    }
                },
                zlevel: 20
            });
            legends.push("SOC4");
        }

        const options = {
            xAxis: {
                type: 'time',
                name: '',
                splitLine: {
                    show: false
                },
            },
            yAxis: [
                {
                    type: 'value',
                    name: "kW",
                    position: "left",
                    splitLine: {
                        show: false
                    },
                },
                {
                    type: 'value',
                    name: "%",
                    positioin: "right",
                    splitLine: {
                        show: false
                    },
                }
            ],
            legend: {
                data: legends
            },
            tooltip: {
                trigger: 'axis',
            },
            toolbox: {
                feature: {
                    /*
                    saveAsImage: {},
                    dataView: {
                        optionToContent: exportCsv
                    },
                    
                    dataZoom: {
                        show: true
                    }*/
                },
                top: 20
            },
            series: series
        };

        if (series.length > 0) {
            return <ReactECharts option={options} style={props.cstyle} notMerge={true} opts={{ renderer: 'canvas' }} theme={colorMode} />;
        } else {
            return null;
        }
    }, [props.cstyle, props.meterData, props.pvData, props.pcsData, props.gensetData, props.socData, colorMode]);

    return (
        <div>
            {chartMemo}
            <br />
        </div>
    );
}

function getTodayMonthEnergy(edge, uri, mmurl, onSuccess, onFail) {
    if (!edge || !uri || !mmurl) return;

    let invokeUrl = mmurl + '/obix/histories/history/queryDemandDayMonth';
    let body = {
        o: 'obix',
        c: [
            { o: 'str', name: 'uri', val: uri.join(",") },
            { o: 'str', name: 'edge', val: edge },
            { o: 'int', name: 'offset', val: 0 }
        ]
    }

    ObixUtil.invokeObix(invokeUrl, body,
        (resp) => {
            //console.dir(resp.data);
            if (resp.data.day.length > 0) {
                onSuccess(resp);
                /*
{
    "day": [
        {
            "ttime": "2023-04-12T00:00:00",
            "energy": 154,
            "reactiveEnergy": 1
        }
    ],
    "month": [
        {
            "ttime": "2023-04-01T00:00:00",
            "energy": 2161,
            "reactiveEnergy": 3
        }
    ]
}
                */
            } else {
                onFail("No record");
            }
        },
        (err) => {
            onFail(err);
        }
    );
}

function getTodayEnergy(edge, uri, mmurl, onSuccess, onFail) {
    if (!edge || !uri || !mmurl) return;
    const date = new Date();
    let s = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    let e = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0);

    let invokeUrl = mmurl + '/obix/histories/history/queryDemandTable';
    let body = {
        o: 'obix',
        c: [
            { o: 'abstime', name: "start", val: ObixUtil.toIsoDate(s) },
            { o: 'abstime', name: "end", val: ObixUtil.toIsoDate(e) },
            { o: 'str', name: 'uri', val: uri },
            { o: 'str', name: 'edge', val: edge },
            { o: 'str', name: 'duration', val: "P1D" }
        ]
    }

    ObixUtil.invokeObix(invokeUrl, body,
        (resp) => {
            console.dir(resp.data);
            if (resp.data.length > 0) {
                onSuccess(resp);
                //setToday(resp.data[0].energy);
            } else {
                onFail("No record");
            }
        },
        (err) => {
            onFail(err);
        }
    );
}

function getMonthEnergy(edge, uri, mmurl, onSuccess, onFail) {
    if (!edge || !uri) return;
    const date = new Date();
    let s = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
    let e = new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0, 0);

    let invokeUrl = mmurl + '/obix/histories/history/queryDemandTable';
    let body = {
        o: 'obix',
        c: [
            { o: 'abstime', name: "start", val: ObixUtil.toIsoDate(s) },
            { o: 'abstime', name: "end", val: ObixUtil.toIsoDate(e) },
            { o: 'str', name: 'uri', val: uri },
            { o: 'str', name: 'edge', val: edge },
            { o: 'str', name: 'duration', val: "P1M" }
        ]
    }

    ObixUtil.invokeObix(invokeUrl, body,
        (resp) => {
            //console.dir(resp.data);
            if (resp.data.length > 0) {
                onSuccess(resp);
                //setToday(resp.data[0].energy);
            } else {
                onFail("No record");
            }
        },
        (err) => {
            onFail(err);
        }
    );
}

function get24Stats(edge, uri, mmurl, onSuccess, onFail) {
    if (!edge || !uri) return;
    const date = new Date();
    let s = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() - 24, date.getMinutes(), date.getSeconds(), 0);
    let e = date;

    let invokeUrl = mmurl + '/obix/histories/history/queryDemandTable';
    let body = {
        o: 'obix',
        c: [
            { o: 'abstime', name: "start", val: ObixUtil.toIsoDate(s) },
            { o: 'abstime', name: "end", val: ObixUtil.toIsoDate(e) },
            { o: 'str', name: 'uri', val: uri },
            { o: 'str', name: 'edge', val: edge },
            { o: 'str', name: 'duration', val: "PT15M" }
        ]
    }

    ObixUtil.invokeObix(invokeUrl, body,
        (resp) => {
            //console.dir(resp.data);
            if (resp.data.length > 0) {
                onSuccess(resp);
            } else {
                onFail("No record");
            }
        },
        (err) => {
            onFail(err);
        }
    );
}

function get24Trends(edge, uri, mmurl, onSuccess, onFail) {
    if (!edge || !uri) return;
    const date = new Date();
    let s = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() - 24, date.getMinutes(), date.getSeconds(), 0);
    let e = date;

    let invokeUrl = mmurl + '/obix/histories/history/queryTable';
    let body = {
        o: 'obix',
        c: [
            { o: 'abstime', name: "start", val: ObixUtil.toIsoDate(s) },
            { o: 'abstime', name: "end", val: ObixUtil.toIsoDate(e) },
            { o: 'str', name: 'uri', val: uri },
            { o: 'str', name: 'edge', val: edge }
        ]
    }

    ObixUtil.invokeObix(invokeUrl, body,
        (resp) => {
            //console.dir(resp.data);
            if (resp.data.length > 0) {
                onSuccess(resp);
            } else {
                onFail("No record");
            }
        },
        (err) => {
            onFail(err);
        }
    );
}

