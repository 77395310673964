import React from 'react';
import { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { AppContext } from '../index';
import { ObixUtil } from '../service/ObixUtil';
import classnames from 'classnames';

export function Monitor(props) {
    // props.objmap
    const widgets = [];  // widget을 저장 
    const sortobj = [];  // 정렬을 할 임시 저장소
    const objtable = props.objmap;

    for (const key of objtable.keys()) {
        const len = objtable.get(key).size;
        sortobj.push({k: key, c: len});
        //console.log(`push ${key} ${len}`);
    }

    sortobj.sort((a, b) => {
        if (b.c !== a.c) {
            return b.c - a.c;
        } else {
            return a.k > b.k ? 1 : a.k  < b.k ? -1 : 0;
        }
    });

    sortobj.forEach((item) => {
        //console.log(`item.k = ${item.k}`);
        widgets.push(<ObixParent key={item.k} obix={objtable.get(item.k)} url={item.k}/>);
    });

    return (        
        <div>
            {widgets}
        </div>
    );
}

function ObixParent(props) {
    const url = props.url;
    const widgets = [];
    let hasAlarm = false;

    // props.obix는 cmap 이 전달되는 것임. 
    //console.log(`props.obix=${props.obix}, key=${props.url}`);
    if (props.obix) {
        for (const ckey of props.obix.keys()) {
            const child = props.obix.get(ckey);
            if (child.o !== "op" && child.o !== "ref" && child.name !== "__time__") {
                const elem = 
                    <ObixChild key={child.name} name={child.name} val={child.val} o={child.o} u={child.u} 
                        status={child.status} display={child.display} displayName={child.displayName}/>;
                widgets.push(elem);
                // 알람인 경우 헤더 색깔 변경
                if (child.status === 'alarm' || child.status === 'fault' || child.status === 'ALARM' || child.status === 'FAULT' || child.o === 'err') {
                    hasAlarm = true;
                }
            }
        }
    }
    if (widgets.length > 0) {
        return (
            <table className="obixtable m-1">
                <thead>
                    <tr>
                        <th colSpan={2} className={classnames({"alarm-header": hasAlarm})}>{url}</th>
                    </tr>
                </thead>
                <tbody>
                    {widgets}
                </tbody>
            </table>
        )
    } else {
        return null;
    }
}

function ObixChild(props) {
    // name, val, o, u, status, display, displayName 
    const [changed, setChanged] = useState(false);
    //const prevValRef = useRef();

    let hasAlarm = false;

    // 값이 변경된 것 색깔 바꿔주기 ????
    useEffect(() => {
        // props.val 이 바뀌면 changed=true하고, 200msec 이후 다시 원래대로 돌린다. 
        //console.log(`${props.name} : ${props.val} = ${changed}`);  // 이거는 잘 찍힘.. 
        // 타이머가 중복되지 않도록 changed=false인 경우에만 타이머 작동 
        if (changed === false) {
            setChanged(true);
            setTimeout(() => setChanged(false), 200);
        }
    }, [props.val]);

    // 중복 같지만 처음 렌더링할 때는 changed=false로 두어서 전체가 초록색이 되지 않도록 함. 
    useEffect(() => {
        setChanged(false);
    }, []);
    if (props.o === "err") {
        return (
            <tr>
                <td className="alarm">{props.o}</td>
                <td className="alarm">{props.display}</td>
            </tr>
        );
    } else if (props.o === "op") {
        return null;
    } else {
        const st = props?.status ? props.status.toLowerCase() : null;
        if (st === "alarm" || st === "fault") {
            hasAlarm = true;
        }
        return (
            <tr className={classnames({"changed": changed})}>
                <td className={classnames({"alarm": hasAlarm, "changed": changed})}>{props.name}</td>
                <td className={classnames({"alarm": hasAlarm, "changed": changed})}>{props.val?.toString()}&nbsp;{props.u}</td>
            </tr>
        )
    } 
}
